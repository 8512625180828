<template>
  <div class="bn-main">
    <div class="bn-text">
      {{ banner && banner.text }}
    </div>
    <div class="bn-user" v-if="banner">
      <a :href="`/user/${banner.user}`">
        {{ banner.username }}
      </a>
    </div>
    <!-- <ProgressBar /> -->
    <!-- <div
      class="bn-history bn-icon"
      :class="{ 'bn-icon-active': showMore === 0 }"
      @click="showHistory"
      title="历史留言"
    >
      <IconText />
    </div>
    <div
      class="bn-info bn-icon"
      :class="{ 'bn-icon-active': showMore === 1 }"
      @click="showInfo"
      title="房间信息"
    >
      <IconInfo />
    </div>
    <div
      class="bn-ban bn-icon"
      :class="{ 'bn-icon-active': showMore === 2 }"
      @click="showBan"
      title="封禁留言"
      v-if="token"
    >
      <IconCancel />
    </div>
    <div
      class="bn-points bn-icon"
      :class="{ 'bn-icon-active': showMore === 3 }"
      @click="showContribute"
      title="贡献积分"
      v-if="token"
    >
      <IconPoints />
    </div> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// import IconList from "vue-material-design-icons/FormatListNumbered.vue";
import IconInfo from "vue-material-design-icons/InformationOutline.vue";
import IconText from "vue-material-design-icons/TextBoxOutline.vue";
import IconPoints from "vue-material-design-icons/CurrencyUsd.vue";
import IconCancel from "vue-material-design-icons/Cancel.vue";
import ProgressBar from "../room/ProgressBar.vue";
export default {
  name: "Banner",
  components: {
    IconText,
    IconInfo,
    IconPoints,
    IconCancel,
    ProgressBar,
  },
  data() {
    return {
      // banner: "",
    };
  },
  computed: {
    ...mapState("account", ["token"]),
    ...mapState("room", ["banner", "showMore"]),
  },
  methods: {
    ...mapMutations("room", ["setShowMore", "setModal"]),
  },
};
</script>

<style scoped>
.bn-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.bn-text {
  width: 100%;
  flex-grow: 1;
  font-size: 1.5rem;
}

.bn-user {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.bn-info {
  position: absolute;
  left: 40px;
  bottom: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.bn-points {
  position: absolute;
  left: 100px;
  bottom: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.bn-live {
  position: absolute;
  left: 130px;
  bottom: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.bn-ban {
  position: absolute;
  left: 70px;
  bottom: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.bn-history {
  position: absolute;
  left: 10px;
  bottom: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
</style>