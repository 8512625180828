<template>
  <div class="room-list">
    <div class="rl-refresh">
      <button class="btn rl-refresh-btn" @click="refresh">
        <IconRefresh />
      </button>
      <div class="rl-refresh-hint" v-show="hint">房间列表已更新</div>
    </div>
    <div class="card">
      <div class="card-header">活跃房间</div>
      <div class="card-body">
        <template v-if="active.length > 0">
          <RoomInfoActive
            v-for="room in active"
            :key="room.roomId"
            :room="room"
          />
        </template>
        <template v-else>
          <div class="rl-void">目前没有活跃房间</div>
        </template>
      </div>
    </div>
    <div class="card">
      <div class="card-header">历史房间</div>
      <div class="card-body">
        <template v-if="archive.length > 0">
          <RoomInfoArchive
            v-for="room in archive"
            :key="room.roomId"
            :room="room"
          />
        </template>
        <template v-else>
          <div class="rl-void">目前没有历史房间</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RoomInfoActive from "./RoomInfoActive";
import RoomInfoArchive from "./RoomInfoArchive";
import IconRefresh from "vue-material-design-icons/Refresh.vue";

export default {
  name: "RoomListMobile",
  components: { RoomInfoActive, RoomInfoArchive, IconRefresh },
  data() {
    return {
      active: [],
      archive: [],
      hint: false,
    };
  },
  methods: {
    refresh() {
      this.active.length = 0;
      this.archive.length = 0;
      this.axios.get("/room/list/active").then(({ data }) => {
        if (data.msg === "OK") {
          this.active = data.rooms;
          this.hint = true;
          setTimeout(() => {
            this.hint = false;
          }, 2000);
        }
      });
      this.axios.get("/room/list/archive").then(({ data }) => {
        if (data.msg === "OK") {
          this.archive = data.rooms;
          this.hint = true;
          setTimeout(() => {
            this.hint = false;
          }, 2000);
        }
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
@import "../../css/btn.css";

.rl-refresh {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
.rl-void {
  padding: 50px;
}
.rl-refresh-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}
.rl-refresh-btn {
  padding: 3px 3px 0px 3px;
}
</style>