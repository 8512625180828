<template>
  <div class="card">
    <div class="card-header">提现</div>

    <div class="card-body pt-sell">
      <div class="form-group">
        <input type="text" v-model="sell" @input="changeSell" />
        积分 = {{ sell / 100 }} 元 （最少0.01元）
      </div>

      <div class="pt-to">请选择提现通道并扫码提供账号</div>
      <img :src="alipay" class="pt-alipay" @click="toAlipay" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import alipay from "../../../img/alipay.png";
export default {
  name: "SettingsBuyPoints",
  data() {
    return {
      sell: 0,
      alipay: alipay,
    };
  },
  computed: {
    ...mapState("account", ["points"]),
  },
  methods: {
    ...mapMutations("account", ["setPoints"]),
    ...mapActions("account", ["refreshPoints"]),

    changeSell() {
      const parse = parseInt(this.sell);
      this.sell = isNaN(parse) ? 0 : Math.floor(parse);
    },
    toAlipay() {
      if (this.sell > this.points) {
        return alert("积分余额不足");
      }
      if (this.sell <= 0) {
        return alert("最少提现0.01元");
      }
      // if (localStorage.getItem("gt-payment")) {
      //   return alert("提现过于频繁");
      // }

      if (window.confirm(`确定要提现${this.sell / 100}元吗？`)) {
        this.axios
          .post(
            "/point/sell/alipay",
            {
              amount: this.sell,
            },
            { auth: true }
          )
          .then(({ data }) => {
            // localStorage.removeItem("gt-payment");
            this.refreshPoints();
            // console.log(data);
            if (data.msg === "OK") {
              this.status = 1;
              alert("提现成功，请查收");
            } else if (data.msg === "INSUFFICIENT_POINTS") {
              this.status = 3;
              alert("积分余额不足");
            } else if (data.msg === "WAIT") {
              this.status = 4;
              this.wait = Math.ceil(data.time / 3600);
            } else {
              this.status = 2;
            }
          });
      }

      // localStorage.setItem(
      //   "gt-payment",
      //   JSON.stringify({
      //     vendor: "Alipay",
      //     type: "S",
      //     amount: this.sell,
      //   })
      // );
      // this.authorize();
    },

    // authorize() {
    //   const protocol = location.protocol.slice(0, -1);
    //   const host = location.host.replace(":", "%3A");
    //   window.open(
    //     `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021003125690779&scope=auth_base&redirect_uri=${protocol}%3A%2F%2F${host}%2Fredirect`
    //   );
    // },
  },
};
</script>

<style scoped>
.justify {
  display: flex;
  justify-content: space-between;
}
input {
  font-size: 1rem;
  text-align: end;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
input:focus {
  outline: none;
}
.pt-sell {
  display: flex;
  flex-direction: column;
}
.pt-to {
  margin-top: 20px;
}

.pt-alipay {
  width: 124px;
  /* 1550: 31 */
  height: 48px;
  /* 600: 12 */
  margin-top: 20px;
  cursor: pointer;
}
</style>