import axios from "axios";
// const axios = require("axios");

axios.interceptors.response.use(
  (response) => {
    if (response.data.msg === "UNAUTHED") {
      localStorage.removeItem("gt-id");
      localStorage.removeItem("gt-username");
      localStorage.removeItem("gt-token");
      localStorage.removeItem("gt-points");
      // window.location.replace("https://www.group-theory.com");
      // location.reload();
      location = `${location.origin}/account`;
    }
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axios.interceptors.request.use(
  (config) => {
    if (config.auth) {
      config.headers.authorization = localStorage.getItem("gt-token");
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// if (location.port === "8080") {
//   axios.defaults.baseURL = `http://${location.hostname}:5000/api`;
// } else if (parseInt(location.host.split(".")[0]) > 0) {
//   axios.defaults.baseURL = `http://${location.hostname}/api`;
// } else {
//   axios.defaults.baseURL = `https://${location.hostname}/api`;
// }
if (location.hostname.includes("qunlun.com")) {
  axios.defaults.baseURL = `https://${location.hostname}/api`;
} else {
  axios.defaults.baseURL = `http://${location.hostname}:5000/api`;
}
axios.defaults.withCredentials = true;
// axios.defaults.crossDomain = true;
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export { axios };
// module.exports = axios;
