<template>
  <div class="ad-page">
    <Header class="hd-top" />

    <div class="ad-container menu" v-if="login">
      <div class="menu-left">
        <div class="menu-group">
          <div
            class="menu-item"
            :class="{ 'menu-item-active': menu === 0 }"
            @click="menu = 0"
          >
            新建
          </div>
          <div
            class="menu-item"
            :class="{ 'menu-item-active': menu === 1 }"
            @click="menu = 1"
          >
            积分
          </div>
          <div
            class="menu-item"
            :class="{ 'menu-item-active': menu === 2 }"
            @click="menu = 2"
          >
            标语
          </div>
        </div>
      </div>

      <div class="menu-right">
        <RoomCreate v-if="menu === 0" />
        <template v-else-if="menu === 1">
          <PointBalance />
          <PointBuy />
        </template>
        <BannerApprove v-else-if="menu === 2" />
      </div>
    </div>
    <div class="ad-container" v-else>请以管理员身份登录</div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import RoomCreate from "../components/RoomCreate.vue";
import PointBuy from "../components/points/PointBuy.vue";
import PointBalance from "../components/points/PointBalance.vue";
import BannerApprove from "../components/banner/BannerApprove.vue";

import { mapState } from "vuex";
export default {
  name: "Admin",
  components: {
    Header,
    RoomCreate,
    PointBuy,
    PointBalance,
    BannerApprove,
  },
  data() {
    return {
      login: false,
      menu: 0,
    };
  },
  computed: {
    ...mapState("account", ["token"]),
  },
  mounted() {
    if (this.token) {
      this.axios.post(`/admin/login`, {}, { auth: true }).then(({ data }) => {
        if (data.msg === "OK") {
          this.login = true;
        } else {
          location = `${location.origin}/account`;
        }
      });
    } else {
      location = `${location.origin}/account`;
    }
  },
};
</script>

<style scoped>
@import "../css/init.css";
/* @import "../css/card.css"; */
@import "../css/form.css";
.ad-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ad-container {
  width: 100%;
  max-width: 960px;
  margin-top: 50px;
}
</style>