import router from "../router/index";
import { axios } from "../axios/axios";

const state = {
  draws: [],
};
const getters = {};
const mutations = {};
const actions = {
  socket_connected({ state, rootState, dispatch }) {
    if (!router.currentRoute.path.includes("lottery")) {
      return;
    }

    this._vm.$socket.client.emit("LOTTERY");
  },
  socket_newDraw({ state, rootState, dispatch }, draw) {
    state.draws.unshift(draw);
  },
  init({ state }) {
    axios.get(`/lottery/recent`).then(({ data }) => {
      if (data.msg === "OK") {
        state.draws = data.draws.sort((a, b) => b.time - a.time);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
