<template>
  <div class="screen">
    <div class="content" style="padding: 20px">
      <Intro v-if="tab === 0" style="width: 100%" />
      <Instructions v-else-if="tab === 1" style="width: 100%" />
      <Contacts v-else-if="tab === 2" style="width: 100%" />
    </div>
    <div class="footer">
      <ul class="tab-list">
        <li class="tab-item" @click="back">
          <div class="tab-icon"><IconArrowLeft /></div>
          <div class="tab-text">首页</div>
        </li>

        <li
          class="tab-item"
          @click="tab = 0"
          :class="{ 'tab-active': tab === 0 }"
        >
          <div class="tab-text">简介</div>
        </li>
        <li
          class="tab-item"
          @click="tab = 1"
          :class="{ 'tab-active': tab === 1 }"
        >
          <div class="tab-text">教程</div>
        </li>
        <li
          class="tab-item"
          @click="tab = 2"
          :class="{ 'tab-active': tab === 2 }"
        >
          <div class="tab-text">联系</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import Contacts from "../components/help/Contacts.vue";
import Intro from "../components/help/Intro.vue";
import Instructions from "../components/help/Instructions.vue";

export default {
  name: "Help",
  components: {
    IconArrowLeft,
    Intro,
    Instructions,
    Contacts,
  },
  data() {
    return {
      tab: 0,
    };
  },

  methods: {
    back() {
      location.assign("/");
    },
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/footer-nav.css";
</style>
