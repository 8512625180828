import Vuex from "vuex";
import Vue from "vue";
import room from "./room";
import account from "./account";
import user from "./user";
import lottery from "./lottery";
// import fake from "./fake";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    room,
    account,
    user,
    lottery,
  },
});
