<template>
  <div class="screen">
    <div class="content" style="padding: 20px">
      <template v-if="tab === 0">
        <div
          class="ar-msg"
          v-for="m in msgPA"
          :key="`${m.type}${m.message}${m.time}`"
        >
          <div class="ar-msg-normal" v-if="m.type === 'M'">
            <div class="ar-msg-info">
              <div class="ar-msg-user">
                <a :href="`/user/${messages[m.message].author}`">
                  {{ messages[m.message].username }}
                </a>
              </div>
              <div
                class="ar-msg-time"
                :title="`发帖时间 ${timeLocale(messages[m.message].time)}`"
              >
                {{ m.time | timeLocale }}
              </div>

              <div class="ar-msg-votes">
                <div class="ar-msg-badge">
                  <IconThumbUp />
                </div>
                <span> {{ m.votes }} </span>
              </div>
            </div>
            <div class="ar-msg-content">
              <ShowExcerpt :height="136">
                {{ messages[m.message].text }}
              </ShowExcerpt>
            </div>
          </div>
          <div class="ar-msg-normal ar-msg-pa" v-else>
            <div class="ar-msg-info">
              <div class="ar-msg-pa">公告</div>
              <div class="ar-msg-time">
                {{ m.time | timeLocale }}
              </div>
            </div>
            <div class="ar-msg-content">
              <ShowExcerpt :height="136">
                {{ m.text }}
              </ShowExcerpt>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="card ar-card" v-if="room">
          <div class="card-header">本聊天室信息</div>
          <div class="card-body">
            <div class="ar-list">
              <li class="ar-item">
                <div class="ar-key">管理员</div>
                <div class="ar-value">
                  <a :href="`/user/${room.owner}`">{{ room.username }}</a>
                </div>
              </li>
              <li class="ar-item">
                <div class="ar-key">开始</div>
                <div class="ar-value">{{ room.start | timeLocale }}</div>
              </li>
              <li class="ar-item">
                <div class="ar-key">结束</div>
                <div class="ar-value">{{ room.end | timeLocale }}</div>
              </li>
              <li class="ar-item">
                <div class="ar-key">周期</div>
                <div class="ar-value">{{ room.cycle }}秒</div>
              </li>
            </div>
          </div>
        </div>

        <div class="card ar-card">
          <div class="card-header">积分贡献榜</div>
          <div class="card-body">
            <div class="ar-list">
              <li class="ar-item">
                <div class="ar-username">用户</div>
                <div class="ar-points">积分</div>
              </li>
              <li class="ar-item" v-for="user in contributors" :key="user.id">
                <div class="ar-username">
                  <a :href="`/user/${user.id}`">
                    {{ user.username }}
                    <span v-if="user.id === id">(我)</span>
                  </a>
                </div>
                <div class="ar-points">
                  {{ user.total }}
                </div>
              </li>
            </div>
          </div>
        </div>

        <div class="card ar-card">
          <div class="card-header">积分收获榜</div>
          <div class="card-body">
            <div class="ar-list">
              <li class="ar-item">
                <div class="ar-username">用户</div>
                <div class="ar-votes">获赞</div>
                <div class="ar-points">积分</div>
              </li>
              <li class="ar-item" v-for="user in distribution" :key="user.user">
                <div class="ar-username">
                  <a :href="`/user/${user.user}`">
                    {{ user.username }}
                    <span v-if="user.user === id">(我)</span>
                  </a>
                </div>
                <div class="ar-votes">
                  {{ user.votes }}
                </div>
                <div class="ar-points">
                  {{ user.amount }}
                </div>
              </li>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="footer">
      <ul class="tab-list">
        <li class="tab-item" @click="back">
          <div class="tab-icon">
            <IconArrowLeft />
          </div>
          <div class="tab-text">首页</div>
        </li>
        <li
          class="tab-item"
          @click="toHistory"
          :class="{ 'tab-active': tab === 0 }"
        >
          <div class="tab-text">历史留言</div>
        </li>
        <li
          class="tab-item"
          @click="toVote"
          :class="{ 'tab-active': tab === 1 }"
        >
          <div class="tab-text">房间信息</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import timeLocale from "../../utils/timeLocale";
import ShowExcerpt from "../components/ShowExcerpt.vue";
import IconArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import IconThumbUp from "vue-material-design-icons/ThumbUp.vue";

export default {
  name: "Archive",
  components: {
    ShowExcerpt,
    IconArrowLeft,
    IconThumbUp,
  },
  data() {
    return {
      room: null,
      history: [],
      messages: {},
      rewards: [],
      pa: [],
      distribution: [],
      tab: 0,
    };
  },
  computed: {
    // ...mapState("account", ["token"]),
    ...mapState("account", ["id"]),
    msgPA() {
      return [
        ...this.history.map((m) => ({
          type: "M",
          ...m,
        })),
        ...this.pa.map((a) => ({ type: "A", message: "", ...a })),
      ].sort((a, b) => a.time - b.time);
    },
    contributors() {
      return Object.values(
        this.rewards.reduce((a, c) => {
          if (a[c.user]) {
            a[c.user]["total"] += c.amount;
          } else {
            a[c.user] = {
              total: c.amount,
              id: c.user,
              username: c.username,
            };
          }
          return a;
        }, {})
      ).sort((a, b) => b.total - a.total);
    },
  },
  filters: {
    timeLocale(e) {
      return timeLocale(e);
    },
  },
  methods: {
    back() {
      location.assign("/");
    },
    toHistory() {
      this.tab = 0;
    },
    toVote() {
      this.tab = 1;
    },
    toInput() {
      this.input = !this.input;
    },
    timeLocale(e) {
      return timeLocale(e);
    },
  },
  mounted() {
    this.axios
      .get(`/room/archive/${this.$route.params.id}`)
      .then(({ data }) => {
        // console.log(data);
        switch (data.msg) {
          case "REDIRECT":
            location.assign(`${location.origin}/room/${data.id}`);
            break;
          case "OK":
            data.messages.forEach((m) => {
              this.messages[m.id] = m;
            });
            this.history = data.history;
            this.pa = data.pa;
            this.rewards = data.rewards;
            this.room = data.room;
            this.distribution = data.distribution;
            break;
        }
      });
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/footer-nav.css";
@import "../css/archive.css";
</style>
