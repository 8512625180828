<template>
  <div>
    <div class="card">
      <div class="card-header">如何留言</div>
      <div class="card-body">
        <span> 在下述输入框中输入留言。 </span>
        <div class="hp-img">
          <img :src="input" alt="输入框" style="width: 400px" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">如何点赞</div>
      <div class="card-body">
        <span> 每轮未点赞时，系统会随机抽取5条留言供登录用户点赞。 </span>
        <div class="hp-img">
          <img :src="random" alt="随机显示" style="width: 400px" />
        </div>
        <span> 当用户点赞后，系统会按所有留言获赞数倒序显示。 </span>
        <div class="hp-img">
          <img :src="sorted" alt="倒序显示" style="width: 400px" />
        </div>
        <span> 此时用户可改点赞别的留言。 </span>
      </div>
    </div>
    <div class="card">
      <div class="card-header">如何历史留言</div>
      <div class="card-body">
        <span>
          每轮点赞结束后，本轮获赞最多的留言将会被记录在房间左侧的历史留言栏中。
        </span>
        <div class="hp-img">
          <img :src="history" alt="历史留言" style="width: 400px" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">如何提现积分</div>
      <div class="card-body">
        <ul>
          <li>顶部导航栏“账号”</li>
          <li>左侧导航栏“积分”</li>
          <li>
            右侧“提现”栏中填入需要提现的积分数后，选择提现渠道（暂时只支持支付宝）
            <div class="hp-img">
              <img :src="withdraw" alt="" style="width: 600px" />
            </div>
          </li>
          <li>
            用手机支付宝扫描桌面浏览器弹出的支付宝页面二维码，并在手机支付宝中确认提供账号信息
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import input from "../../../img/input.png";
import random from "../../../img/random.png";
import sorted from "../../../img/sorted.png";
import history from "../../../img/history.png";
import withdraw from "../../../img/withdraw.png";
export default {
  name: "Instructions",
  data() {
    return {
      menu: 0,
      input,
      random,
      sorted,
      history,
      withdraw,
    };
  },
};
</script>

<style scoped>
ul {
  margin-left: 15px;
}
</style>