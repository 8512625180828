<template>
  <div class="cc-list">
    <div
      class="cc-item"
      :class="{ 'cc-item-active': showMore === 0 }"
      @click="showHistory"
    >
      历史评论
    </div>
    <div
      class="cc-item"
      :class="{ 'cc-item-active': showMore === 1 }"
      @click="showRandom"
    >
      当前
      <span class="cc-current" v-if="voted">最热</span>
      <span class="cc-current" v-else>随机</span>评论
      <span class="cc-countdown" title="本轮投票剩余时间">{{ eta }}</span>
    </div>
    <!-- <div
      class="cc-item"
      :class="{ 'cc-item-active': showMore === 2 }"
      @click="showTop"
    >
      当前最热评论
    </div> -->
    <div
      class="cc-item"
      :class="{ 'cc-item-active': showMore === 3 }"
      @click="showInfo"
    >
      房间信息
    </div>
    <div
      class="cc-item"
      :class="{ 'cc-item-active': showMore === 4 }"
      @click="showBan"
      v-if="token"
    >
      封禁评论
    </div>
    <!-- <div
      class="cc-item"
      :class="{ 'cc-item-active': showMore === 5 }"
      @click="showContrib"
      v-if="token"
    >
      贡献积分
    </div> -->
    <div class="cc-item" @click="showInput">输入评论</div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "CommentControls",
  data() {
    return {
      timer: null,
      eta: null,
    };
  },
  computed: {
    ...mapState("account", ["token"]),
    ...mapState("room", ["showMore", "voted", "last", "cycle", "start", "end"]),
  },
  methods: {
    ...mapMutations("room", ["setShowMore", "setModal"]),
    showInput() {
      if (!this.token) {
        return alert("该操作需要登录");
      }
      this.setModal(2);
    },
    showHistory() {
      this.setShowMore(0);
    },
    showRandom() {
      this.setShowMore(1);
    },
    showTop() {
      this.setShowMore(2);
    },
    showInfo() {
      this.setShowMore(3);
    },
    showBan() {
      if (!this.token) {
        return alert("该操作需要登录");
      }
      this.setShowMore(4);
    },
    showContrib() {
      if (!this.token) {
        return alert("该操作需要登录");
      }
      this.setShowMore(5);
    },
    getProgress() {
      const now = new Date().valueOf() / 1000;
      if (this.start && now > this.start && this.end && now < this.end) {
        this.eta = Math.ceil(
          this.start +
            Math.ceil((now - this.start) / this.cycle) * this.cycle -
            now
        );
      }

      if (this.end > 0 && now > this.end) {
        clearInterval(this.timer);
        this.eta = null;
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getProgress();
    }, 100);
  },
};
</script>

<style scoped>
.cc-list {
  width: 100%;
  height: 100%;
}

.cc-item {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  background-color: #eee;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-left: 20px;
  overflow: hidden;
  cursor: pointer;
}

.cc-item-active {
  background-color: #ccc;
}
.cc-current {
  font-weight: 900;
}
.cc-countdown {
  font-weight: 500;
  color: red;
  margin-left: 10px;
  right: 10px;
  top: 20%;
  display: flex;
  height: 60px;
  align-items: center;
}
</style>