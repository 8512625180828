<template>
  <div class="ct-main">
    <div class="ct-close" @click="() => setShowMore(0)">
      <IconClose />
    </div>
    <div class="card">
      <div class="card-header">积分奖励</div>
      <div class="card-body justify">
        <input
          type="number"
          required="required"
          class="form-underline"
          v-model="reward"
        />
        <div class="rc-points">
          可用积分 {{ points }} <IconRefresh @click="refreshPoints" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">聊天室横幅</div>
      <div class="card-body">
        <div v-for="b in banners" :key="b.id">
          <div
            class="ba-item"
            :class="{ 'ba-item-active': selected === b.id }"
            @click="selected = b.id"
          >
            {{ b.text }}
          </div>
        </div>
      </div>
    </div>

    <div class="btn-group">
      <button class="btn-group-btn" @click="onSubmit" :disabled="status < 0">
        确定
      </button>

      <div class="btn-group-hint" v-if="status === 1">成功</div>
      <div class="btn-group-hint" v-else-if="status === 2">积分不够</div>
    </div>
  </div>
</template>

<script>
import IconClose from "vue-material-design-icons/Close.vue";
import IconRefresh from "vue-material-design-icons/Refresh.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import epoch from "../../../utils/epoch";
export default {
  name: "Contribute",
  components: { IconRefresh, IconClose },
  data() {
    return {
      reward: 0,
      banners: [],
      selected: null,
      status: 0,
    };
  },
  computed: {
    ...mapState("account", ["points", "token", "username"]),
    ...mapState("room", ["end"]),
  },
  methods: {
    ...mapActions("account", ["refreshPoints"]),
    ...mapMutations("room", ["setShowMore"]),
    onSubmit() {
      const reward = parseInt(this.reward);
      if (reward > this.points || reward === 0) {
        this.status = 2;
        return;
      }
      const now = epoch();
      if (now > this.end) {
        return alert("聊天已结束");
      }

      this.$socket.client.emit("POST_REWARD", {
        token: this.token,
        username: this.username,
        room: this.$route.params.id,
        points: parseInt(this.reward),
        banner: this.selected,
      });
      this.setShowMore(0);

      // this.status = -1;
      // this.axios
      //   .post(
      //     "/room/reward",
      //     {
      //       room: this.$route.params.id,
      //       reward: this.reward,
      //       banner: this.banner,
      //     },
      //     { auth: true }
      //   )
      //   .then(({ data }) => {
      //     if (data.msg === "OK") {
      //       this.status = 1;
      //     }
      //   });
    },
    getBanners() {
      this.axios
        .get("/banner/approved/mine", { auth: true })
        .then(({ data }) => {
          if (data.msg === "OK") {
            this.banners = data.banners;
          }
        });
    },
  },
  created() {
    this.refreshPoints();
    this.getBanners();
  },
};
</script>

<style scoped>
.ct-main {
  padding-top: 20px;
  position: relative;
}
.ct-close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 24px;
  width: 24px;
}

.justify {
  display: flex;
  justify-content: space-between;
}
.rc-points {
  display: flex;
  align-items: center;
}
.ba-item {
  cursor: pointer;
}
.ba-item-active {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
