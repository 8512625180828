<template>
  <div class="hp-main">
    <Header class="hd-top" />
    <div class="hp-container">
      <h1 v-if="status === -1">正在处理中...</h1>
      <h1 v-else-if="status === 1">交易完成</h1>
      <h1 v-else-if="status === 2">交易失败</h1>
      <h1 v-else-if="status === 3">积分余额不足</h1>
      <h1 v-else-if="status === 4">交易过于频繁，请{{ wait }}小时后再试</h1>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Header from "../components/Header";

export default {
  name: "Redirect",
  components: {
    Header,
  },
  data() {
    return {
      status: 0,
      wait: null,
      agent: null,
    };
  },
  methods: {
    ...mapActions("account", ["refreshPoints"]),
  },
  mounted() {
    const authCode = this.$route.query.auth_code;
    if (authCode) {
      this.status = -1;
      this.axios
        .post("/user/auth", { code: authCode }, { auth: true })
        .then(({ data }) => {
          const userId = data.userId;
          const payment = JSON.parse(localStorage.getItem("gt-payment"));

          if (userId && payment) {
            if (payment.type === "S") {
              this.axios
                .post(
                  "/point/sell",
                  {
                    vendorId: userId,
                    ...payment,
                  },
                  { auth: true }
                )
                .then(({ data }) => {
                  localStorage.removeItem("gt-payment");
                  this.refreshPoints();
                  // console.log(data);
                  if (data.msg === "OK") {
                    this.status = 1;
                  } else if (data.msg === "INSUFFICIENT_POINTS") {
                    this.status = 3;
                  } else if (data.msg === "WAIT") {
                    this.status = 4;
                    this.wait = Math.ceil(data.time / 3600);
                  } else {
                    this.status = 2;
                  }
                });
            }
          }
        });
    }
  },
};
</script>

<style scoped>
@import "../css/init.css";
.hp-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hp-container {
  width: 100%;
  max-width: 960px;
  margin-top: 50px;
}
</style>