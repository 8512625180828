<template>
  <div class="progress">
    <div class="bar" :style="{ width: `${width}%` }"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProgressBar",
  data() {
    return {
      width: 0,
      timer: null,
    };
  },
  computed: {
    ...mapState("room", ["last", "cycle", "start", "end"]),
  },
  methods: {
    getProgress() {
      const now = new Date().valueOf() / 1000;
      if (this.start && now > this.start && this.end && now < this.end) {
        this.width =
          (100 *
            (now -
              this.start -
              Math.floor((now - this.start) / this.cycle) * this.cycle)) /
          this.cycle;
      }

      if (this.end > 0 && now > this.end) {
        clearInterval(this.timer);
        this.width = 0;
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getProgress();
    }, 10);
  },
};
</script>

<style scoped>
.progress {
  width: 100%;
  height: 2px;
}
.bar {
  height: 100%;
  background-color: #f88;
}
</style>