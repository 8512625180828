<template>
  <div>
    <div class="card">
      <div class="card-header">房间名称</div>
      <div class="card-body">
        <input
          type="text"
          required="required"
          class="form-control"
          @input="onNameChange"
          v-model="name"
        />
        <!-- <span class="rc-hint">{{ hintName }}</span> -->
      </div>
    </div>
    <div class="card">
      <div class="card-header">始末时间</div>
      <div class="card-body se-times">
        <div>
          <input
            type="date"
            class="form-underline"
            v-model="dateStart"
            required
          />
          <input
            type="time"
            class="form-underline"
            v-model="timeStart"
            required
          />
        </div>
        <span> 至 </span>
        <div>
          <input
            type="date"
            class="form-underline"
            v-model="dateEnd"
            required
          />
          <input
            type="time"
            class="form-underline"
            v-model="timeEnd"
            required
          />
        </div>
        <!-- <span class="rc-hint">{{ hintTime }}</span> -->
      </div>
    </div>

    <div class="card">
      <div class="card-header">间隔时间（秒）</div>
      <div class="card-body">
        <input
          type="number"
          required="required"
          class="form-control"
          @input="onTimeChange"
          v-model="time"
        />
      </div>
    </div>

    <button
      class="btn-group-btn rc-submit"
      @click="onSubmit"
      :disabled="status < 0"
    >
      确定
    </button>

    <div class="rc-hint" v-if="status === 1">
      <span
        >房间 {{ name }} 已创建，立即<a :href="`/room/${id}`" class="room-name"
          >前往</a
        ></span
      >
    </div>
    <div class="rc-hint" v-if="status === 2">房间名称不能为空</div>
  </div>
</template>

<script>
import dblDigit from "../../utils/dblDigit";
// import IconRefresh from "vue-material-design-icons/Refresh.vue";
export default {
  name: "RoomCreate",
  // components: { IconRefresh },
  data() {
    return {
      name: "",
      time: 10,
      dateStart: null,
      timeStart: null,
      dateEnd: null,
      timeEnd: null,
      id: null,
      // showHintName: false,
      // showHintTime: false,
      // showHintStart: false,
      // showHintEnd: false,
      // balance: 0,
      // reward: 0,
      // banner: "",
      status: 0,
    };
  },
  methods: {
    onNameChange() {
      this.name = this.name.trim();
    },
    onTimeChange() {
      this.time = parseInt(this.time);
    },
    onSubmit() {
      const epochStart = new Date(`${this.dateStart}T${this.timeStart}`);
      const epochEnd = new Date(`${this.dateEnd}T${this.timeEnd}`);

      if (!this.name.trim()) {
        this.status = 2;
        return;
      }
      this.status = -1;
      this.axios
        .post(
          "/room/new",
          {
            name: this.name,
            start: Math.floor(epochStart.valueOf() / 1000),
            end: Math.floor(epochEnd.valueOf() / 1000),
            cycle: this.time,
          },
          { auth: true }
        )
        .then(({ data }) => {
          if (data.msg === "OK") {
            this.status = 1;
            this.id = data.id;
          }
        });
    },
  },
  mounted() {
    const dts = new Date();
    const dte = new Date(dts.valueOf() + 3600000);
    this.dateStart = `${dts.getFullYear()}-${dblDigit(
      dts.getMonth() + 1
    )}-${dblDigit(dts.getDate())}`;
    this.dateEnd = `${dte.getFullYear()}-${dblDigit(
      dte.getMonth() + 1
    )}-${dblDigit(dte.getDate())}`;
    this.timeStart = `${dblDigit(dts.getHours())}:${dblDigit(
      dts.getMinutes()
    )}`;
    this.timeEnd = `${dblDigit(dte.getHours())}:${dblDigit(dte.getMinutes())}`;
  },
};
</script>

<style scoped>
.rc-hint {
  color: rgba(0, 0, 0, 0.7);
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.justify {
  display: flex;
  justify-content: space-between;
}
.rc-points {
  display: flex;
  align-items: center;
}

.room-name {
  text-decoration: none;
}
.se-times {
  display: flex;
  justify-content: space-between;
}
.rc-submit {
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.4);
  margin-top: 1rem;
}
</style>
