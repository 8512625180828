<template>
  <div class="hs-main" ref="hs-frame" @scroll="handleScroll">
    <div class="hs-msg-list" v-if="noMsg > 0">
      <div
        class="hs-msg-item"
        v-for="m in historyMsgPA"
        :key="`${m.type}${m.id}${m.time}`"
      >
        <MsgPA v-if="m.type == 'A'" :id="m.id" />
        <MsgHistory v-else :id="m.id" :time="m.time" :votes="m.votes" />
      </div>
    </div>
    <div class="hs-msg-none" v-else>- 目前没有历史留言 -</div>
    <div class="hs-btn-pos">
      <div class="hs-btn" v-show="show">
        <IconArrowDown @click="scrollToBottom" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IconArrowDown from "vue-material-design-icons/ArrowDown.vue";
import MsgHistory from "./MsgHistory";
import MsgPA from "./MsgPA";

export default {
  name: "History",
  components: { MsgHistory, MsgPA, IconArrowDown },
  data() {
    return {
      show: false,
      time: null,
    };
  },
  computed: {
    ...mapState("room", ["messages"]),
    ...mapGetters("room", ["historyMsgPA"]),
    noMsg() {
      return this.historyMsgPA.length;
    },
  },
  watch: {
    noMsg() {
      const scroller = this.$refs["hs-frame"];

      if (
        scroller.scrollTop >=
        scroller.scrollHeight - scroller.clientHeight - 50
      ) {
        this.scrollToBottom();
      }
    },
  },
  methods: {
    // ...mapMutations("room", ["updDispMsgId"]),
    handleScroll() {
      const scroller = this.$refs["hs-frame"];
      this.show =
        scroller.scrollTop < scroller.scrollHeight - scroller.clientHeight - 50;
    },
    scrollToBottom() {
      const scroller = this.$refs["hs-frame"];

      this.$nextTick(() => {
        scroller.scrollTop = scroller.scrollHeight;
      });
    },
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
.hs-main {
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* background-color: #f8f8ff; */
  position: relative;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  scrollbar-width: none;
}
.hs-msg-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.hs-msg-none {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hs-btn-pos {
  position: absolute;
  bottom: 35px;
  right: 35px;
}

.hs-btn {
  position: fixed;
  display: block;
  width: 28px;
  height: 28px;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  color: #f5f5f5;
  background: #555777;
  box-shadow: 0 0 3px gray;
  font-size: 20px;
  font-weight: bold;
}
</style>
