<template>
  <div class="pa">
    <div class="pa-left"></div>
    <div class="pa-right">
      <div class="pa-header">
        <span class="pa-pa">公告</span>
        <span class="pa-time">于{{ timeAgo }}前</span>
      </div>
      <div class="pa-body">
        <ShowExcerpt :height="136">
          {{ msg.text }}
        </ShowExcerpt>
      </div>
    </div>
  </div>
</template>

<script>
import ShowExcerpt from "../ShowExcerpt";
import timeAgo from "../../../utils/timeAgo";
import { mapState } from "vuex";

export default {
  name: "MsgPA",
  props: ["id"],
  components: {
    ShowExcerpt,
  },
  data() {
    return {
      timeAgo: null,
    };
  },
  computed: {
    ...mapState("room", ["messages"]),
    msg() {
      return this.messages[this.id];
    },
  },
  methods: {
    refreshTime() {
      this.timeAgo = timeAgo(this.msg.time);
      setTimeout(() => {
        this.refreshTime();
      }, 5000);
    },
  },
  mounted() {
    this.refreshTime();
  },
};
</script>

<style scoped>
/* .msg-deck {
  position: relative;
  display: flex;
  align-items: center;
} */
.pa {
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #fee;
  color: #000;
  position: relative;
  /* z-index: 2; */
  /* transition: transform 0.2s ease-in-out; */
}
.pa-left {
  min-height: 36px;
  min-width: 36px;
  margin-right: 20px;
}
.pa-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pa-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
}
.pa-pa {
  font-weight: 500;
}
.pa-time {
  margin-left: 10px;
  color: #888;
}
.pa-body {
  flex-grow: 1;
}
</style>