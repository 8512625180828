<template>
  <div class="msg">
    <div class="msg-left">
      <div class="msg-votes">
        <div class="msg-badge">
          <IconThumbUp />
        </div>
        <span> {{ votes }} </span>
      </div>
      <div class="msg-ref" v-if="msg.ref" @click="() => goToLive(msg.ref)">
        <img
          class="msg-ref-img"
          :src="lives[msg.ref].avatar"
          :alt="lives[msg.ref].username"
        />
      </div>
    </div>
    <div class="msg-right">
      <div class="msg-header">
        <a :href="`/user/${msg.author}`" class="msg-user">
          {{ msg.username }}
        </a>
        <span class="msg-time">于{{ timeAgo }}前</span>

        <template v-if="owner === userId">
          <div class="msg-toggle" @click="showOption">
            <div v-if="option === `${id}${time}`"><IconMenuUp /></div>
            <div v-else><IconMenuDown /></div>
          </div>
          <div class="msg-option" v-if="option === `${id}${time}`">
            <MsgOption :msg="id" />
          </div>
        </template>
      </div>
      <div class="msg-body">
        <ShowExcerpt :height="136">
          {{ msg.text }}
        </ShowExcerpt>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import IconThumbUp from "vue-material-design-icons/ThumbUp.vue";
import IconMenuUp from "vue-material-design-icons/MenuUp.vue";
import IconMenuDown from "vue-material-design-icons/MenuDown.vue";
import ShowExcerpt from "../ShowExcerpt";
import timeAgo from "../../../utils/timeAgo";
import MsgOption from "./MsgOption.vue";

export default {
  name: "MsgHistory",
  props: ["id", "time", "votes"],
  components: {
    ShowExcerpt,
    IconThumbUp,
    IconMenuUp,
    IconMenuDown,
    MsgOption,
  },
  data() {
    return {
      timeAgo: null,
      // delta: 0,
      // sliding: false,
      // panning: false,
      // pin: true,
    };
  },
  computed: {
    ...mapState("account", { userId: "id" }),
    ...mapState("room", ["messages", "owner", "option", "lives"]),
    msg() {
      return this.messages[this.id];
    },
  },
  methods: {
    ...mapActions("room", ["setFocus"]),
    refreshTime() {
      this.timeAgo = timeAgo(this.time);
      setTimeout(() => {
        this.refreshTime();
      }, 5000);
    },
    ...mapMutations("room", ["setOption"]),
    showOption() {
      this.setOption(`${this.id}${this.time}`);
    },
    goToLive(id) {
      if (this.lives[id].online) {
        this.setFocus(id);
      } else {
        alert("该主播已下播");
      }
    },
    // reply() {
    //   this.setRefMsg(this.id);
    //   this.setModal(2);
    // },

    // unpin() {
    //   this.pin = false;
    //   setTimeout(() => {
    //     this.setReadMsg(this.id);
    //     this.updDispMsgId();
    //   }, 500);
    // },
  },
  mounted() {
    this.refreshTime();
  },
};
</script>

<style scoped>
/* .msg-deck {
  position: relative;
  display: flex;
  align-items: center;
} */
.msg {
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #eee;
  color: #000;
  position: relative;
  /* z-index: 2; */
  /* transition: transform 0.2s ease-in-out; */
}
.msg-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 20px;
}
.msg-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.msg-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg-body {
  flex-grow: 1;
}
.msg-user {
  font-weight: 500;
  /* height: 30px; */
  /* display: flex; */
  /* align-items: center; */
}
.msg-time {
  margin-left: 10px;
  color: #888;
  /* font-size: 80%; */
  /* height: 30px; */
  /* display: flex; */
  /* align-items: center; */
}
.msg-votes {
  width: 36px;
  height: 36px;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.msg-ref {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;
}
.msg-ref-img {
  width: 36px;
  height: 36px;
}
/* .msg-vote {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.msg-vote-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  border: 1px solid #333;
  border-radius: 50%;
  box-shadow: 0 0 3px 3px #aaa;
  cursor: pointer;
} */

.msg-badge {
  /* opacity: 0.2; */
  transform: scale(0.7);
  /* display: flex;
  align-items: center; */
}
/* .msg-yes {
  color: green;
}
.msg-no {
  color: red;
}
.msg-slide {
  transition: transform 500ms ease-out;
} */
/* .msg-opt {
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 1;
} */
/* .msg-opt-item {
  height: 50px;
  width: 50px;
  line-height: 65px;
}
.msg-opt-item:nth-child(odd) {
  background-color: rgba(255, 0, 0, 0.4);
}
.msg-opt-item:nth-child(even) {
  background-color: rgba(255, 255, 0, 0.4);
} */

.msg-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 2rem;
}
.msg-option {
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 10;
}
</style>