<template>
  <div class="room-list">
    <div class="rl-refresh">
      <button class="btn rl-refresh-btn" @click="refresh">
        <IconRefresh />
      </button>
      <div class="rl-refresh-hint" v-show="hint">房间列表已更新</div>
    </div>
    <template v-if="rooms.length > 0">
      <RoomInfoActive v-for="room in rooms" :key="room.roomId" :room="room" />
    </template>
    <template v-else>
      <div class="rl-void">目前没有房间</div>
    </template>
  </div>
</template>

<script>
import RoomInfoActive from "./RoomInfoActive";
import IconRefresh from "vue-material-design-icons/Refresh.vue";

export default {
  name: "RoomListActive",
  components: { RoomInfoActive, IconRefresh },
  data() {
    return {
      rooms: [],
      hint: false,
    };
  },
  methods: {
    refresh() {
      this.rooms.length = 0;
      this.axios.get("/room/list/active").then(({ data }) => {
        if (data.msg === "OK") {
          this.rooms = data.rooms;
          this.hint = true;
          setTimeout(() => {
            this.hint = false;
          }, 2000);
        }
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
@import "../../css/btn.css";

.rl-refresh {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
.rl-void {
  padding: 50px;
}
.rl-refresh-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}
.rl-refresh-btn {
  padding: 3px 3px 0px 3px;
}
</style>