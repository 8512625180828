<template>
  <div class="ba-main">
    <div class="card">
      <div class="card-header">你的被封锁留言</div>
      <div class="card-body">
        <div
          class="ba-ban"
          :class="{ 'ba-ban-active': activeMsgs.includes(m) }"
          v-for="m in Object.keys(myBannedMsg)"
          :key="m"
        >
          <template v-if="messages[m]">
            {{ messages[m].text }}
          </template>
          <template v-else>
            {{ m }} <span @click="() => getMessage(m)">(查看)</span>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="card">
      <div class="card-header">封锁用户</div>
      <div class="card-body">
        <div class="ba-ban" v-for="u in Object.keys(allBannedUser)" :key="u">
          <div class="ba-ban-item" @click="() => unbanUser(u)">x</div>
          {{ allBannedUser[u] }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import IconClose from "vue-material-design-icons/Close.vue";
import IconRefresh from "vue-material-design-icons/Refresh.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "BanForUser",
  components: { IconRefresh, IconClose },
  data() {
    return {
      // reward: 0,
      // banner: "",
      // status: 0,
    };
  },
  computed: {
    // ...mapState("account", ["points", "token", "username"]),
    ...mapState("room", ["myBannedMsg", "messages", "active"]),
    activeMsgs() {
      return Object.values(this.active);
    },
  },
  methods: {
    ...mapMutations("room", ["setShowMore"]),
    ...mapActions("room", ["getMessage"]),
  },
};
</script>

<style scoped>
.ba-main {
  padding: 20px;
  position: relative;
}
.ba-close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 24px;
  width: 24px;
}

.justify {
  display: flex;
  justify-content: space-between;
}
.rc-points {
  display: flex;
  align-items: center;
}
.ba-ban {
  position: relative;
  padding: 10px;
  border-radius: 5px;
}
.ba-ban-item {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.ba-ban-active {
  background-color: rgba(0, 0, 255, 0.1);
}
</style>
