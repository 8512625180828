<template>
  <div class="card">
    <div class="card-header">修改</div>
    <div class="card-body">
      <div class="form-group">
        <label class="form-label">旧密码</label>

        <input type="password" class="form-control" v-model="oldp" />
      </div>
      <div class="form-group">
        <label class="form-label">新密码</label>

        <input type="password" class="form-control" v-model="newp" />
        <div class="form-text">
          密码由8个以上字符(A-Za-z0-9)组成，其中至少有1个大写字母1个小写字母和1个数字
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">新密码确认</label>

        <input type="password" class="form-control" v-model="confp" />
      </div>
      <div class="btn-group">
        <button class="btn-group-btn" @click="change" :disabled="status < 0">
          修改
        </button>
        <div class="btn-group-hint">
          <p v-if="status === 1">修改成功</p>
          <p v-else-if="status === 2">旧密码错误</p>
          <p v-else-if="status === 3">新密码不符合要求</p>
          <p v-else-if="status === 4">新密码两次输入不同</p>
          <p v-else-if="status === -1">修改中...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validatePassword } from "../../../utils/validators";
export default {
  name: "SettingsPassword",
  data() {
    return {
      oldp: "",
      newp: "",
      confp: "",
      status: 0,
    };
  },
  methods: {
    change() {
      if (!validatePassword(this.newp)) {
        this.status = 3;
        return;
      }
      if (this.newp !== this.confp) {
        this.status = 4;
        return;
      }
      this.status = -1;
      this.axios
        .post(
          "/user/password",
          { old: this.oldp, new: this.newp },
          { auth: true }
        )
        .then(({ data }) => {
          if (data.msg === "OK") this.status = 1;
          else if (data.msg === "WRONG") this.status = 2;
          else if (data.msg === "INVALID") this.status = 3;
        });
    },
  },
};
</script>

<style scoped>
</style>