<template>
  <div>
    <div class="card">
      <div class="card-header">手机</div>
      <div class="card-body">
        <input type="text" class="form-control" v-model="mobile" ref="mobile" />
      </div>
    </div>
    <div class="card">
      <div class="card-header">密码</div>
      <div class="card-body">
        <input
          type="password"
          class="form-control"
          v-model="password"
          ref="password"
        />
      </div>
    </div>
    <div class="login-submit">
      <button class="btn" @click="submit">提交</button>
    </div>
    <div class="login-hint">
      <span v-if="hint === 1">手机号码格式不正确</span>
      <span v-else-if="hint === 2">密码格式不正确</span>
      <span v-else-if="hint === 3">用户不存在</span>
      <span v-else-if="hint === 4">密码错误</span>
    </div>
  </div>
</template>

<script>
import { validatePassword, validateMobile } from "../../../utils/validators";

export default {
  name: "LogisterLogin",
  data() {
    return {
      mobile: "",
      password: "",
      hint: 0,
    };
  },
  watch: {
    mobile(v) {
      if (v.length > 0) {
        const last = parseInt(v.slice(-1)).toString();
        if (!(last >= 0 && last <= 9) || v.length > 11) {
          this.mobile = v.slice(0, -1);
        }
      }
    },
  },
  methods: {
    submit() {
      if (!validateMobile(this.mobile)) {
        this.hint = 1;
        this.$refs.mobile.focus();
        return;
      }
      if (!validatePassword(this.password)) {
        this.hint = 2;
        this.$refs.password.focus();
        return;
      }

      this.hint = 0;
      // if (this.menu === 0) {
      this.axios
        .post("/user/login/password", {
          mobile: this.mobile,
          password: this.password,
        })
        .then(({ data }) => {
          // console.log(data);
          switch (data.msg) {
            case "NO_USER":
              this.hint = 3;
              this.$refs.mobile.focus();
              break;
            case "WRONG_PASSWORD":
              this.hint = 4;
              this.$refs.password.focus();
              break;
            case "OK":
              localStorage.setItem("gt-token", data.token);
              localStorage.setItem("gt-username", data.username);
              localStorage.setItem("gt-id", data.id);
              localStorage.setItem("gt-points", data.points);
              location.assign("/");
              break;
          }
        });
      // } else {
      //   this.axios
      //     .post("/user/register", {
      //       email: this.email,
      //       password: this.password,
      //     })
      //     .then(({ data }) => {
      //       switch (data.msg) {
      //         case "OK":
      //           localStorage.setItem("gt-token", data.token);
      //           localStorage.setItem("gt-username", data.username);
      //           localStorage.setItem("gt-id", data.id);
      //           location = location.origin;
      //           break;
      //         case "USER_EXISTS":
      //           this.status = 7;
      //           this.$refs.email.focus();
      //           break;
      //         case "EMAIL_INVALID":
      //           this.status = 2;
      //           this.$refs.email.focus();
      //           break;
      //         case "PASSWORD_INVALID":
      //           this.status = 3;
      //           this.$refs.password.focus();
      //           break;
      //       }
      //     });
      // }
    },
  },
};
</script>

<style scoped>
.login-submit {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
}
.login-hint {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  color: rgba(255, 0, 0, 0.5);
}
</style>