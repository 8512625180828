<template>
  <div class="md-back" @click="close">
    <div class="md-content" :class="{ 'md-content-input': showModal == 2 }">
      <LiveAdd v-if="showModal === 1" />
      <Input v-else-if="showModal === 2" />
      <!-- <ModalDisplay v-else-if="showModal === `display`" /> -->
    </div>
    <!-- <div class="md-content" v-else-if="showModal === 2">
      <RefChain :id="refMsgId" />
      <Input :refId="refMsgId" />
    </div> -->
    <!-- <div class="md-content" v-else-if="showModal === 3">
      <span>hello World</span>
    </div> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LiveAdd from "./modal/LiveAdd.vue";
// import MsgHistory from "./MsgHistory";
// import ModalDisplay from "./ModalDisplay";
// import RefChain from "./RefChain";
import Input from "./room/Input.vue";

export default {
  name: "Modal",
  components: { LiveAdd, Input },
  computed: {
    ...mapState("room", ["refMsgId", "showModal"]),
  },
  methods: {
    ...mapMutations("room", ["setModal"]),
    close(e) {
      if (e.target.className === "md-back") {
        this.setModal(0);
      }
    },
  },
};
</script>

<style scoped>
.md-back {
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-content {
  width: 660px;
  background-color: white;
}
.md-content-input {
  width: 400px;
  transform: translateX(240px);
}
</style>