<template>
  <div class="mo-border">
    <div class="mo-item" @click="unbanMsg" v-if="isMsgBanned">解封信息</div>
    <div class="mo-item" @click="banMessage" v-else>封锁信息</div>
    <div class="mo-item" @click="unbanAuthor" v-if="isUserBanned">解封用户</div>
    <div class="mo-item" @click="banAuthor" v-else>封锁用户</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "MsgOption",
  props: ["msg"],
  computed: {
    ...mapState("account", ["token"]),
    ...mapState("room", [
      "active",
      "allBannedMsg",
      "allBannedUser",
      "messages",
    ]),
    ...mapGetters("room", ["isOwner"]),
    // id() {
    //   return this.active[this.author];
    // },
    author() {
      return this.messages[this.msg].author;
    },
    isMsgBanned() {
      return this.allBannedMsg[this.msg];
    },
    isUserBanned() {
      return this.allBannedUser[this.author];
    },
  },
  methods: {
    ...mapMutations("room", ["setOption"]),
    // hideMsg() {
    //   this.setHidden({ id: this.id, hide: true });
    //   this.setOption(this.id);
    // },
    // showMsg() {
    //   this.setHidden({ id: this.id, hide: false });
    //   this.setOption(this.id);
    // },
    banMessage() {
      this.$socket.client.emit("BAN_MESSAGE", {
        token: this.token,
        room: this.$route.params.id,
        msg: this.msg,
        // user: this.author,
      });
      this.setOption(null);
    },
    banAuthor() {
      this.$socket.client.emit("BAN_USER", {
        token: this.token,
        room: this.$route.params.id,
        user: this.author,
        username: this.messages[this.msg].username,
      });
      this.setOption(null);
    },
    unbanMsg() {
      this.$socket.client.emit("UNBAN_MESSAGE", {
        token: this.token,
        room: this.$route.params.id,
        msg: this.msg,
        // user: this.author,
      });
      this.setOption(null);
    },
    unbanAuthor() {
      this.$socket.client.emit("UNBAN_USER", {
        token: this.token,
        room: this.$route.params.id,
        user: this.author,
      });
      this.setOption(null);
    },
  },
};
</script>

<style scoped>
.mo-border {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #ccc;
  padding: 10px;
}
.mo-item {
  font-weight: 500;
  cursor: pointer;
}
</style>