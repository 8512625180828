<template>
  <div>
    <div class="card">
      <div class="card-header">什么是群论？</div>
      <div class="card-body">
        群论是一个新型聊天室，它试图通过引入<strong>留言点赞</strong>来解决聊天室困境（The
        Chatroom
        Conundrum，指传统聊天室在同时发言人数达到一定数量（如大于1000）时，由于信息量过大且没有筛选使得留言不可读）。
      </div>
    </div>

    <div class="card">
      <div class="card-header">群论与传统聊天室对比</div>
      <div class="card-body">
        <div class="hp-table">
          <div class="hp-item">
            <div class="hp-attribute"></div>
            <div class="hp-qunlun hp-header">群论</div>
            <div class="hp-chat hp-header">传统聊天室</div>
          </div>
          <div class="hp-item">
            <div class="hp-attribute">聊天界面</div>
            <div class="hp-qunlun">
              历史高赞留言和当前活跃留言分栏显示，当前活跃留言按照获赞数降序排列
            </div>
            <div class="hp-chat">所有留言按照时间顺序显示</div>
          </div>
          <div class="hp-item">
            <div class="hp-attribute">聊天热点</div>
            <div class="hp-qunlun">按赞数排列，热点突出</div>
            <div class="hp-chat">所有留言平权，热点不突出</div>
          </div>
          <div class="hp-item">
            <div class="hp-attribute">水贴刷屏</div>
            <div class="hp-qunlun">排序机制自动屏蔽</div>
            <div class="hp-chat">无法自动屏蔽，手动屏蔽繁琐</div>
          </div>
          <div class="hp-item">
            <div class="hp-attribute">人数上线</div>
            <div class="hp-qunlun">
              人数增长对留言点赞排序机制无显著影响，理论人数无上限
            </div>
            <div class="hp-chat">
              有上限，因为同时聊天人数过多时，留言全部显示，信息量过大不可读
            </div>
          </div>
          <div class="hp-item">
            <div class="hp-attribute">奖金激励</div>
            <div class="hp-qunlun">获赞多的用户按比例分得条幅广告积分收益</div>
            <div class="hp-chat">无</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">积分如何分配</div>
      <div class="card-body">
        房间总积分按用户总获赞数等比例分配。设某房间总积分奖励为<math
          xmlns="http://www.w3.org/1998/Math/MathML"
          display="inline"
        >
          <mi>S</mi> </math
        >，用户<math
          xmlns="http://www.w3.org/1998/Math/MathML"
          display="inline"
        >
          <mi>i</mi> </math
        >获得的总赞数为<math
          xmlns="http://www.w3.org/1998/Math/MathML"
          display="inline"
        >
          <msub>
            <mi>v</mi>
            <mi>i</mi>
          </msub> </math
        >，则他获得的积分为
        <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
          <msub>
            <mi>p</mi>
            <mi>i</mi>
          </msub>
          <mo>=</mo>
          <mfrac>
            <msub>
              <mi>v</mi>
              <mi>i</mi>
            </msub>
            <mrow>
              <munder>
                <mo>&#x2211;<!-- ∑ --></mo>
                <mi>i</mi>
              </munder>
              <msub>
                <mi>v</mi>
                <mi>i</mi>
              </msub>
            </mrow>
          </mfrac>
          <mi>S</mi>.
        </math>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style scoped>
.hp-table {
}
.hp-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.hp-table .hp-item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.hp-qunlun {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hp-chat {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hp-attribute {
  width: 20%;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.hp-header {
  font-weight: 600;
  display: flex;
  justify-content: center;
}
</style>