<template>
  <div id="ip-form" class="ip-control">
    <template v-if="isBanned">
      您因为留言违反本网站规定，被禁止在本房间发言
    </template>
    <template v-else-if="!myMsgId || writing">
      <textarea
        ref="ip-text"
        class="ip-text"
        rows="3"
        cols="100"
        v-model="message"
        @keydown.enter="handleEnter"
      ></textarea>
      <div class="ip-lower">
        <div class="ip-announce">
          <select v-model="type">
            <!-- inline object literal -->
            <option :value="0">对所有人说</option>
            <template v-if="focus">
              <option :value="1">对当前主播说</option>
            </template>
            <template v-if="isOwner">
              <option :value="2">公告</option>
            </template>
          </select>
          <!-- <input type="radio" value="0" v-model="type" />  
          <label>所有人</label>
          <input type="radio" value="1" v-model="type" />  
          <label>主播</label>
          <template v-if="isOwner">
            <input type="radio" value="2" v-model="type" />
            <label>公告</label>
          </template> -->
        </div>
        <div class="ip-submit">
          <!-- <span class="ip-ctrl">ctrl+enter 或</span> -->
          <button class="ip-click" @click="submitMessage" title="或Ctrl+Enter">
            发送
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="ip-mine">
        <div class="ip-ref">
          <img
            class="ip-img"
            :src="lives[messages[myMsgId].ref].avatar"
            alt=""
            v-if="myMsgId && messages[myMsgId].ref !== ''"
          />
        </div>
        <ShowExcerpt :height="80">
          {{ myMsgId && messages[myMsgId].text }}
        </ShowExcerpt>
      </div>
      <div class="ip-change" @click="newMsg">
        <IconPencil />
      </div>
    </template>
  </div>
</template>

<script>
// import IconArrowUp from "vue-material-design-icons/ArrowUp.vue";
import IconPencil from "vue-material-design-icons/PencilOutline.vue";
import ShowExcerpt from "../ShowExcerpt.vue";

import { mapGetters, mapMutations, mapState } from "vuex";
import epoch from "../../../utils/epoch";

export default {
  name: "Input",
  components: {
    IconPencil,
    ShowExcerpt,
  },
  data() {
    return {
      message: "",
      type: 0,
    };
  },
  computed: {
    ...mapState("account", ["token", "username"]),
    ...mapState("room", [
      "start",
      "end",
      "messages",
      "writing",
      "isBanned",
      "focus",
      "lives",
    ]),
    ...mapGetters("room", ["myMsgId", "isOwner"]),
  },
  methods: {
    ...mapMutations("room", ["setWriting"]),
    newMsg() {
      const msg = this.myMsgId && this.messages[this.myMsgId];
      this.message = msg.text;
      if (msg.ref == "") {
        this.type = 0;
      } else {
        this.type = 1;
      }
      this.setWriting(true);
    },
    handleEnter(e) {
      if (e.ctrlKey) {
        this.submitMessage();
      }
    },
    submitMessage() {
      if (this.isBanned) {
        return;
      }
      // if (this.isOwner && this.announce) {
      //   this.$socket.client.emit("POST_PA", {
      //     token: this.token,
      //     room: this.$route.params.id,
      //     to: this.to,
      //     text: this.message,
      //   });
      //   this.message = "";
      //   this.change(false);
      //   return;
      // }
      const now = epoch();
      if (now < this.start) {
        return alert(`聊天${this.start - now}秒后开始`);
      }
      if (now > this.end) {
        return alert(`聊天已结束`);
      }
      if (!this.token) {
        this.message = "";
        return alert("请先登录再留言");
      }
      const message = this.message.trim();
      if (this.myMsgId && this.messages[this.myMsgId].text === message) {
        this.setWriting(false);
        return;
      }

      if (this.message.trim()) {
        let ref;
        switch (this.type) {
          case 0:
            ref = "";
            break;
          case 1:
            ref = this.focus;
            break;
          case 2:
            ref = "pa";
            break;
        }
        this.$socket.client.emit("POST_MESSAGE", {
          token: this.token,
          username: this.username,
          room: this.$route.params.id,
          ref,
          text: this.message,
        });

        this.message = "";
        this.setWriting(false);
      } else {
        alert("消息不能为空");
      }
      // this.input = false;
    },
    // autoHeight() {
    //   const textarea = this.$refs["ip-text"];
    //   textarea.style.height = "0px";
    //   textarea.style.height = textarea.scrollHeight + "px";
    // },
  },
};
</script>

<style scoped>
/* @import "../css/init.css"; */

.ip-control {
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* background-color: #f8fff8; */
  align-items: center;
  justify-content: center;
  height: 160px;
  position: relative;
}

.ip-text {
  resize: none;
  overflow: auto;
  /* max-height: 94px;
  min-height: 28px;
  height: 28px; */
  width: 100%;
  /* margin: auto 10px; */
  font-size: 15px;
  border-radius: 3px;
  padding: 3px 8px;
  font-family: inherit;
}

.ip-mine {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  width: 100%;
  height: 100%;
}

/* .ip-send {
  display: block;
  width: 28px;
  height: 28px;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  color: #f5f5f5;
  background: #555777;
  box-shadow: 0 0 3px gray;
  font-size: 20px;
  font-weight: bold;
} */
.ip-lower {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}
.ip-submit {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ip-announce {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ip-announce-text {
  margin-left: 5px;
}
.ip-click {
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;

  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  cursor: pointer;
}
.ip-ctrl {
  margin-right: 10px;
}
.ip-change {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.ip-ref {
  width: 48px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ip-img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 20px;
}
</style>