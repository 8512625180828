import { axios } from "../axios/axios";

const state = {
  id: null,
  username: null,
  location: null,
  // messages: [],
  // rooms: {},
  // admin: [],
  // follow: [],
};
const getters = {
  // roomIds: (state) => [...new Set(state.messages.map((m) => m.roomId))],
  // msgInRoom: (state) => (id) => state.messages.filter((m) => m.roomId === id),
};
const actions = {
  initUser({ state }, id) {
    axios.get(`/user/${id}/init`).then(({ data }) => {
      switch (data.msg) {
        case "NO_USER":
          alert("用户不存在");
          location.assign("/");
          break;
        case "OK":
          state.id = data.id;
          state.username = data.username;
          state.location = data.location;
          break;
      }
    });
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
