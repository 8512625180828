<template>
  <div class="la-main">
    <div class="card">
      <div class="card-header">添加直播</div>
      <div class="card-body">
        <div class="la-content">
          <div class="la-control">
            <span class="la-preurl">https://v.douyin.com/</span>
            <input
              type="text"
              name="share"
              id="share"
              v-model="id"
              class="la-input"
            />
            <button @click="addLive" class="la-submit">确定</button>
            <span v-if="statusAddLive == -1">请稍等...</span>
            <span v-else-if="statusAddLive == 1">直播已添加</span>
            <span v-else-if="statusAddLive == 2">地址不正确</span>
            <span v-else-if="statusAddLive == 3">直播已存在</span>
          </div>
          <template v-if="showSteps">
            <span>步骤1:</span>
            <img :src="share1" class="la-img" />
            <span>步骤2:</span>
            <img :src="share2" class="la-img" />
            <span>步骤3:</span>
            <img :src="share3" class="la-img" />
            <span
              >步骤4: 将复制的文字 https://v.douyin.com/abcdefgh 中的 abcdefgh
              填入并确定</span
            >
          </template>
          <span
            v-else
            @click="showSteps = true"
            style="cursor: pointer; margin-top: 10px"
            >显示步骤</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import share1 from "../../../img/share1.jpg";
import share2 from "../../../img/share2.jpg";
import share3 from "../../../img/share3.jpg";
export default {
  name: "LiveAdd",
  data() {
    return {
      id: null,
      share1,
      share2,
      share3,
      showSteps: false,
    };
  },
  computed: {
    ...mapState("account", ["token"]),
    ...mapState("room", ["statusAddLive"]),
  },
  methods: {
    ...mapMutations("room", ["setStatusAddLive"]),
    addLive() {
      this.setStatusAddLive(-1);
      this.$socket.client.emit("ADD_LIVE", {
        token: this.token,
        room: this.$route.params.id,
        shareid: this.id,
      });
    },
  },
};
</script>

<style scoped>
.la-main {
  margin: 20px;
}

.la-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.la-img {
  width: 300px;
  margin: 0 auto;
}
.la-preurl {
  font-family: monospace;
  font-size: 14px;
}
.la-input {
  width: 100px;
  border: none;
  border-bottom: #aaa 1px solid;
  outline: none;
  font-family: monospace;
  font-size: 14px;
}
.la-submit {
  margin: 0 10px;
}
</style>