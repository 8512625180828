<template>
  <div class="card">
    <div class="card-header">充值</div>
    <div class="card-body justify">
      <div class="form-group">
        <input type="text" v-model="buy" @input="changeBuy" /> 元 =
        {{ buy * 100 }} 积分
      </div>
      <button class="btn-group-btn" @click="buyPoints">确定</button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "PointBuy",
  data() {
    return {
      buy: 0,
    };
  },
  computed: {
    ...mapState("account", ["points"]),
  },
  methods: {
    ...mapMutations("account", ["setPoints"]),
    changeBuy() {
      const parse = parseFloat(this.buy);
      this.buy = isNaN(parse) ? 0 : Math.floor(parse * 100) / 100;
    },
    buyPoints() {
      if (this.buy > 0) {
        this.axios
          .post("/point/buy", { amount: this.buy * 100 }, { auth: true })
          .then(({ data }) => {
            if (data.msg === "OK") {
              this.setPoints(data.balance);
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.justify {
  display: flex;
  justify-content: space-between;
}
input {
  font-size: 1rem;
  text-align: end;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
input:focus {
  outline: none;
}
</style>