<template>
  <div>
    <div class="card">
      <div class="card-header">请选择登录或注册渠道</div>
      <div class="card-body">
        <img :src="alipay" class="reg-alipay" @click="toAlipay" />
      </div>
    </div>
  </div>
</template>

<script>
import alipay from "../../../img/alipay.png";
import touch from "../../../utils/touch";
export default {
  name: "RegisterAlipay",
  data() {
    return {
      alipay,
    };
  },
  computed: {
    touch() {
      return touch();
    },
  },
  methods: {
    toAlipay() {
      const url = `${location.origin}/login/alipay`;
      const appId = location.hostname.includes("qunlun.com")
        ? "2021003125690779"
        : "2021003125667993";
      const web = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${appId}&scope=auth_user&redirect_uri=${encodeURIComponent(
        url
      )}`;
      if (!this.touch) {
        return window.open(web);
      }
      const mobile = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
        web
      )}`;

      if (navigator.userAgent.indexOf("MicroMessenger") < 0) {
        window.open(mobile);
      } else {
        location.assign("/redirect/wechat");
      }
    },
  },
};
</script>

<style scoped>
.reg-alipay {
  height: 60px;
  cursor: pointer;
}
</style>