<template>
  <div class="cr-main">
    <Header />
    <Modal v-if="showModal > 0" />
    <div class="cr-panel">
      <div class="cr-panel-left">
        <LiveList />
      </div>
      <div class="cr-panel-center">
        <div class="cr-banner">
          <Banner />
        </div>
        <div class="cr-content">
          <div class="cr-content-left">
            <VideoPlayer />
          </div>
          <div class="cr-content-right">
            <History v-if="showMore == 0" />
            <Current v-else-if="showMore == 1 || showMore == 2" />
            <RoomInfo v-else-if="showMore === 3" />
            <BanForOwner v-else-if="showMore === 4 && id && owner === id" />
            <BanForUser v-else-if="showMore === 4 && id && owner !== id" />
            <Contribute v-else-if="showMore === 5 && id" />
          </div>
        </div>
      </div>
      <div class="cr-panel-right">
        <CommentControls />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import History from "../components/msg/History";
import Current from "../components/msg/Current.vue";
import Header from "../components/Header";
// import Input from "../components/room/Input";
import ProgressBar from "../components/room/ProgressBar.vue";
import Banner from "../components/banner/Banner.vue";
import RoomInfo from "../components/room/RoomInfo.vue";
import Contribute from "../components/room/Contribute.vue";
import BanForUser from "../components/room/BanForUser.vue";
import BanForOwner from "../components/room/BanForOwner.vue";
import VideoPlayer from "../components/room/VideoPlayer.vue";
import LiveList from "../components/room/LiveList.vue";
import CommentControls from "../components/room/CommentControls.vue";
import Modal from "../components/Modal";

export default {
  name: "Desktop",
  components: {
    History,
    Header,
    // More,
    // Clicker,
    // Input,
    // Control,
    Modal,
    Current,
    ProgressBar,
    Banner,
    RoomInfo,
    Contribute,
    BanForOwner,
    BanForUser,
    VideoPlayer,
    LiveList,
    CommentControls,
  },
  computed: {
    ...mapState("room", ["showMore", "showModal", "owner", "voted"]),
    ...mapState("account", ["id"]),
  },
  mounted() {
    this.$socket.client.open();
    // console.log("open");
  },
};
</script>

<style scoped>
@import "../css/init.css";

.cr-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cr-panel {
  flex-grow: 1;
  /* height: 100%; */
  width: 1260px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* position: relative; */
}
.cr-panel-left {
  height: 100%;
  width: 150px;

  /* background-color: #000; */
}
.cr-panel-center {
  height: 100%;
  width: 960px;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.cr-panel-right {
  height: 100%;
  width: 150px;
  /* background-color: blue; */
}

.cr-banner {
  background-color: #f8f8f8;
  min-height: 100px;
}

.cr-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}
.cr-content-left {
  width: 50%;
  height: 100%;
}
.cr-content-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.cr-options {
  flex-grow: 1;
  overflow: auto;
  background-color: #fff8f8;
  /* display: flex; */
}

.cr-input {
  background-color: #f8fff8;
}

/* .cr-control {
  flex-grow: 1;
  overflow: auto;
} */
</style>
