<template>
  <div class="main">
    <Header class="hd-top" />
    <div class="container menu">
      <div class="menu-left">
        <div class="menu-group">
          <div class="menu-item" :class="{ 'menu-item-active': menu === 0 }" @click="menu = 0">
            活跃房间
          </div>
          <div class="menu-item" :class="{ 'menu-item-active': menu === 1 }" @click="menu = 1">
            历史房间
          </div>
        </div>
      </div>
      <div class="menu-right">
        <RoomListActive v-if="menu === 0" />
        <RoomListArchive v-else-if="menu === 1" />
      </div>
    </div>
    <Footer v-if="footer" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import RoomListArchive from "../components/list/RoomListArchive.vue";
import RoomListActive from "../components/list/RoomListActive.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Rooms",
  data() {
    return {
      footer: true,
      menu: 0,
    };
  },
  components: {
    Header,
    RoomListArchive,
    RoomListActive,
    Footer,
  },
  mounted() {
    // setTimeout(() => {
    //   this.footer = false;
    // }, 10000);
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/menu.css";
@import "../css/desktop.css";
</style>