const { axios } = require("../axios/axios");

const state = {
  username: localStorage.getItem("gt-username") || null,
  token: localStorage.getItem("gt-token") || null,
  id: localStorage.getItem("gt-id") || null,
  points: localStorage.getItem("gt-points") || null,
  mobile: localStorage.getItem("gt-mobile") || null,
  alipay: localStorage.getItem("gt-alipay") || null,
};
const getters = {};
const mutations = {
  setUsername(state, username) {
    state.username = username;
    localStorage.setItem("gt-username", username);
  },
  setId(state, id) {
    state.id = id;
    localStorage.setItem("gt-id", id);
  },
  setToken(state, token) {
    state.token = token;
    localStorage.setItem("gt-token", token);
  },
  setPoints(state, points) {
    state.points = points;
    localStorage.setItem("gt-points", points);
  },
  // setAlipay(state, alipay) {
  //   state.alipay = alipay;
  //   localStorage.setItem("gt-alipay", alipay);
  // },
  logout() {
    localStorage.removeItem("gt-id");
    localStorage.removeItem("gt-username");
    localStorage.removeItem("gt-token");
    localStorage.removeItem("gt-points");
    location.assign("/");
  },
};
const actions = {
  refreshPoints({ commit }) {
    axios.get("/point/balance", { auth: true }).then(({ data }) => {
      if (data.msg === "OK") {
        commit("setPoints", data.balance);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
