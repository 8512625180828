<template>
  <div class="header">
    <div class="header-brand">
      <a class="header-name" href="/">群论</a>
    </div>
    <div class="header-tabs">
      <div class="header-tab">
        <a href="/help">帮助</a>
      </div>
      <div class="header-tab">
        <a href="/">房间</a>
      </div>
      <div class="header-tab">
        <a href="/account">账号({{ token ? shortName : `未登录` }})</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapState("account", ["username", "token"]),
    shortName() {
      return this.username.length > 8
        ? `${this.username.substring(0, 8)}...`
        : this.username;
    },
  },
};
</script>

<style scoped>
/* @import "../css/init.css"; */
.header {
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  /* z-index: 100; */
}
a {
  color: #fff;
}
.header-tabs {
  display: flex;
}
.header-tab {
  margin-left: 20px;
  padding: 0;
}

.header-name {
  font-weight: 900;
}
</style>