<template>
  <div class="room">
    <div class="room-primary">
      <span>{{ room.name }}</span>
      <IconArrowRight class="room-icon" @click="toRoom" />
    </div>
    <div class="room-time">{{ timeStart }} - {{ timeEnd }}</div>
    <div class="room-secondary">
      <a :href="`/user/${room.owner}`" class="room-admin">{{
        room.username
      }}</a>
    </div>
  </div>
</template>

<script>
import dblDigit from "../../../utils/dblDigit";
import IconArrowRight from "vue-material-design-icons/ArrowRight.vue";
import IconAccount from "vue-material-design-icons/Account.vue";

export default {
  name: "RoomInfoArchive",
  props: ["room"],
  components: { IconArrowRight, IconAccount },
  computed: {
    timeStart() {
      const dt = new Date(this.room.start * 1000);
      return `${dt.getMonth() + 1}.${dt.getDate()} ${dblDigit(
        dt.getHours()
      )}:${dblDigit(dt.getMinutes())}`;
    },
    timeEnd() {
      const dt = new Date(this.room.end * 1000);
      return `${dt.getMonth() + 1}.${dt.getDate()} ${dblDigit(
        dt.getHours()
      )}:${dblDigit(dt.getMinutes())}`;
    },
  },
  methods: {
    toRoom() {
      location.assign(`/archive/${this.room.roomId}`);
    },
  },
};
</script>

<style scoped>
/* @import "../css/init.css"; */
.room {
  border: #f0f0f0 1px solid;
  border-radius: 5px;
  box-shadow: 2px 0px 2px #f0f0f0;
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
}
.room-primary {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.room-name {
  font-weight: 900;
}
.room-time {
  display: flex;
  font-family: monospace;
  margin-bottom: 10px;
}

.room-secondary {
  display: flex;
  justify-content: space-between;
  font-size: 90%;
}
.room-icon {
  display: block;
  width: 28px;
  height: 28px;
  border: 2px solid #555777;
  border-radius: 50%;
  color: #555777;
  transform: scale(0.8);
  position: relative;
  left: 10px;
  cursor: pointer;
}
.room-admin {
  color: rgba(0, 0, 0, 0.8);
}
.room-online {
  display: flex;
  align-items: center;
}
.room-online-icon {
  height: 24px;
  width: 24px;
  transform: scale(0.8);
}
.room-online-no {
  font-weight: 700;
}
</style>