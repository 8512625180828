import Vue from "vue";
import VueRouter from "vue-router";

import MHomepage from "../views/mobile/Homepage";
import MAccount from "../views/mobile/Account";
import MChatroom from "../views/mobile/Chatroom";
import MArchive from "../views/mobile/Archive";
import MUser from "../views/mobile/User";
import MHelp from "../views/mobile/Help";

import Homepage from "../views/desktop/Homepage";
import Chatroom from "../views/desktop/Chatroom";
import User from "../views/desktop/User";
import Account from "../views/desktop/Account";
import Redirect from "../views/desktop/Redirect";
import Admin from "../views/desktop/Admin";
import LoginAlipay from "../views/desktop/LoginAlipay";
import Help from "../views/desktop/Help";
import Archive from "../views/desktop/Archive";
import RedirectWechat from "../views/mobile/RedirectWechat";

import touch from "../utils/touch";

Vue.use(VueRouter);

const desktop = [
  {
    path: "/",
    component: touch() ? MHomepage : Homepage,
  },
  {
    path: "/account",
    component: touch() ? MAccount : Account,
  },
  {
    path: "/redirect",
    component: Redirect,
  },
  {
    path: "/login/alipay",
    component: LoginAlipay,
  },
  {
    path: "/room/:id",
    component: touch() ? MChatroom : Chatroom,
  },
  {
    path: "/archive/:id",
    component: touch() ? MArchive : Archive,
  },
  {
    path: "/user/:id",
    component: touch() ? MUser : User,
  },
  {
    path: "/admin",
    component: Admin,
  },
  {
    path: "/help",
    component: touch() ? MHelp : Help,
  },
  {
    path: "/redirect/wechat",
    component: RedirectWechat,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // routes: window.innerWidth <= 480 ? mobile : desktop,
  routes: desktop,
});

export default router;
