<template>
  <div class="card">
    <div class="card-header">积分</div>
    <div class="card-body justify">
      {{ points >= 0 ? points : "请刷新" }}
      <button class="btn-group-btn" @click="refreshPoints">刷新</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PointBalance",

  computed: {
    ...mapState("account", ["points"]),
  },
  methods: {
    ...mapActions("account", ["refreshPoints"]),
  },
};
</script>

<style scoped>
.justify {
  display: flex;
  justify-content: space-between;
}
input {
  font-size: 1rem;
  text-align: end;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
input:focus {
  outline: none;
}
</style>