<template>
  <div class="hp-main">
    <Header class="hd-top" />

    <div class="hp-container menu">
      <div class="menu-left">
        <div class="menu-group">
          <div
            class="menu-item"
            :class="{ 'menu-item-active': menu === 0 }"
            @click="menu = 0"
          >
            简介
          </div>
          <div
            class="menu-item"
            :class="{ 'menu-item-active': menu === 1 }"
            @click="menu = 1"
          >
            教程
          </div>
          <div
            class="menu-item"
            :class="{ 'menu-item-active': menu === 2 }"
            @click="menu = 2"
          >
            联系
          </div>
        </div>
      </div>
      <div class="menu-right">
        <Intro v-if="menu === 0" />
        <Instructions v-else-if="menu === 1" />
        <Contacts v-else-if="menu === 2" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Intro from "../components/help/Intro.vue";
import Instructions from "../components/help/Instructions.vue";
import Contacts from "../components/help/Contacts.vue";

export default {
  name: "Help",
  components: {
    Header,
    Intro,
    Instructions,
    Contacts,
  },
  data() {
    return {
      menu: 0,
    };
  },
};
</script>

<style scoped>
@import "../css/init.css";

.hp-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hp-container {
  width: 100%;
  max-width: 960px;
  margin-top: 50px;
}
ul {
  padding-left: 20px;
  margin-top: 20px;
}

.hp-item {
  display: flex;
  padding: 10px 0;
}
.hp-attribute {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hp-qunlun {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.hp-chat {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hp-table .hp-item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.hp-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>