const dblDigit = require("./dblDigit");

module.exports = function (e) {
  const dt = new Date(e > 1e12 ? e : e * 1000);
  return `${dt.getFullYear()}.${dblDigit(dt.getMonth() + 1)}.${dblDigit(
    dt.getDate()
  )} ${dblDigit(dt.getHours())}:${dblDigit(dt.getMinutes())}:${dblDigit(
    dt.getSeconds()
  )}`;
};
