<template>
  <div class="screen">
    <div class="content" style="padding: 20px">
      <RoomListMobile v-if="tab === 0" style="width: 100%" />
    </div>
    <div class="footer">
      <ul class="tab-list">
        <li
          class="tab-item"
          @click="tab = 0"
          :class="{ 'tab-active': tab === 0 }"
        >
          <div class="tab-text">房间</div>
        </li>
        <li
          class="tab-item"
          @click="toAccount"
          :class="{ 'tab-active': tab === 1 }"
        >
          <div class="tab-text">账号({{ token ? shortName : `未登录` }})</div>
        </li>
        <li
          class="tab-item"
          @click="toHelp"
          :class="{ 'tab-active': tab === 2 }"
        >
          <div class="tab-text">关于</div>
        </li>
        <!-- <li
          class="tab-item"
          @click="tab = 2"
          v-else
          :class="{ 'tab-active': tab === 2 }"
        >
          <div class="tab-text">设置</div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RoomListMobile from "../components/list/RoomListMobile.vue";

// import RoomTabs from "../components/RoomTabs";
// import Logister from "../components/Logister";
// import Settings from "../components/Settings";

export default {
  name: "Homepage",
  components: {
    RoomListMobile,
    // RoomTabs,
    // Logister,
    // Settings,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapState("account", ["username", "token"]),
    shortName() {
      return this.username.length > 8
        ? `${this.username.substring(0, 8)}...`
        : this.username;
    },
  },
  methods: {
    toAccount() {
      this.tab = 1;
      location.assign("/account");
    },
    toHelp() {
      this.tab = 2;
      location.assign("help");
    },
  },
  mounted() {
    // document.title = this.$appName;
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/footer-nav.css";
/* .screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex-grow: 1;
  overflow: auto;
  padding: 20px;
} */
</style>
