<template>
  <div class="btn-group">
    <button class="btn-group-btn logout" @click="logout">退出登录</button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "SettingsLogout",
  methods: {
    ...mapMutations("account", ["logout"]),
  },
};
</script>

<style scoped>
.logout {
  width: 100%;
  color: white;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  margin-top: 1rem;
}
</style>