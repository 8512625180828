<template>
  <div class="lv-list">
    <div class="lv-item" @click="addLive">
      <IconPlus class="lv-item-img lv-item-plus" />
      <div class="lv-item-info lv-item-add">添加直播</div>
    </div>
    <div
      class="lv-item"
      :class="{ 'lv-item-focus': live.userid == focus }"
      v-for="live in sortedLives"
      :key="live.userid"
      @click="() => setFocus(live.userid)"
    >
      <img class="lv-item-img" :src="live.avatar" :alt="live.username" />
      <div class="lv-item-info">
        <div class="lv-item-name">
          {{ live.username }}
        </div>
        <div class="lv-item-viewer">{{ live.count }}人观看</div>
      </div>
    </div>
    <div class="lv-item-last"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import IconPlus from "vue-material-design-icons/Plus.vue";

export default {
  name: "LiveList",
  components: {
    IconPlus,
  },
  computed: {
    ...mapState("account", ["token"]),
    ...mapState("room", ["focus"]),
    ...mapGetters("room", ["sortedLives", "liveViews"]),
  },
  methods: {
    ...mapActions("room", ["setFocus"]),
    ...mapMutations("room", ["setModal"]),
    addLive() {
      if (this.token) {
        this.setModal(1);
      } else {
        alert("请先登录再添加直播");
      }
    },
  },
};
</script>

<style scoped>
.lv-list {
  /* position: absolute;
  top: 0px;
  left: -150px; */
  height: 100%;
  width: 100%;
  overflow: auto;
}

.lv-item-img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

.lv-item-info {
  width: 100%;
  height: 60px;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.lv-item-name {
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.lv-item-viewer {
  height: 24px;
  font-size: 90%;
  white-space: nowrap;
  overflow: hidden;
  color: #888;
}

.lv-item {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  background-color: #eee;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 6px;
  overflow: hidden;
  cursor: pointer;
}

.lv-item-focus {
  background-color: #ccc;
}
.lv-item-plus {
  scale: 2;
  position: relative;
  top: 24px;
  left: 8px;
}
.lv-item-add {
  position: relative;
  left: 13px;
}
.lv-item-last {
  height: 60px;
}
</style>