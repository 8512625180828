<template>
  <div class="screen">
    <Banner style="background-color: rgba(255, 0, 0, 0.05)" />
    <div class="content" style="padding: 20px">
      <History v-if="showMore === 0" style="width: 100%" />
      <RoomInfo v-else-if="showMore === 1" style="width: 100%" />
      <BanForOwner
        v-else-if="showMore === 2 && id && owner === id"
        style="width: 100%"
      />
      <BanForUser
        v-else-if="showMore === 2 && id && owner !== id"
        style="width: 100%"
      />
      <Contribute v-else-if="showMore === 3 && id" style="width: 100%" />
      <Current v-else-if="showMore === 4" />
      <Input
        v-if="input"
        style="
          background-color: rgb(250, 250, 255);
          width: 100%;
          position: absolute;
          bottom: 0px;
          z-index: 10;
        "
      />
    </div>
    <ProgressBar />
    <div class="footer">
      <ul class="tab-list">
        <li class="tab-item" @click="back">
          <div class="tab-icon">
            <IconArrowLeft />
          </div>
          <div class="tab-text">首页</div>
        </li>
        <li
          class="tab-item"
          @click="toHistory"
          :class="{ 'tab-active': showMore === 0 }"
        >
          <div class="tab-text">历史</div>
        </li>
        <li
          class="tab-item"
          @click="toVote"
          :class="{ 'tab-active': showMore === 4 }"
        >
          <div class="tab-text">当前</div>
        </li>
        <li class="tab-item" @click="toInput" :class="{ 'tab-active': input }">
          <div class="tab-text">输入</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IconArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import History from "../components/msg/History";
import Current from "../components/msg/Current.vue";
import Input from "../components/room/Input";
import ProgressBar from "../components/room/ProgressBar.vue";
import Banner from "../components/banner/Banner.vue";
import RoomInfo from "../components/room/RoomInfo.vue";
import Contribute from "../components/room/Contribute.vue";
import BanForUser from "../components/room/BanForUser.vue";
import BanForOwner from "../components/room/BanForOwner.vue";

export default {
  name: "Chatroom",
  components: {
    IconArrowLeft,
    History,
    Current,
    Input,
    ProgressBar,
    Banner,
    RoomInfo,
    Contribute,
    BanForUser,
    BanForOwner,
  },
  data() {
    return {
      input: false,
    };
  },
  computed: {
    // ...mapState("account", ["token"]),
    ...mapState("room", ["showMore", "owner"]),
    ...mapState("account", ["id"]),
  },
  watch: {
    showMore(v) {
      this.input = false;
    },
  },
  methods: {
    ...mapMutations("room", ["setShowMore"]),
    back() {
      location.assign("/");
    },
    toHistory() {
      this.setShowMore(0);
      // this.tab = 0;
    },
    toVote() {
      this.setShowMore(4);
      // this.tab = 1;
    },
    toInput() {
      this.input = !this.input;
    },
  },
  mounted() {
    this.$socket.client.open();
    // console.log("open");
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/footer-nav.css";
</style>
