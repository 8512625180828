<template>
  <div class="rd">
    <div class="rd-mine" v-if="isMine">我</div>
    <div class="rd-vote" @click="() => voteMsg(active[author])">
      <IconThumbUp />
    </div>
    <template v-if="owner === id">
      <div class="rd-toggle" @click="showOption">
        <div v-if="option === active[author]"><IconMenuUp /></div>
        <div v-else><IconMenuDown /></div>
      </div>
      <div class="rd-option" v-if="option === active[author]">
        <MsgOption :msg="active[author]" />
      </div>
    </template>

    <div class="rd-body">
      <ShowExcerpt :height="136">
        {{ msg && msg.text }}
      </ShowExcerpt>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import IconThumbUp from "vue-material-design-icons/ThumbUp.vue";
import IconMenuUp from "vue-material-design-icons/MenuUp.vue";
import IconMenuDown from "vue-material-design-icons/MenuDown.vue";
import ShowExcerpt from "../ShowExcerpt";
import MsgOption from "./MsgOption.vue";
// import timeAgo from "../../utils/timeAgo";
// import epoch from "../../utils/epoch";

export default {
  name: "MsgRandom",
  props: ["author"],
  components: {
    ShowExcerpt,
    IconThumbUp,
    MsgOption,
    IconMenuUp,
    IconMenuDown,
  },
  computed: {
    ...mapState("room", [
      "messages",
      "active",
      "option",
      "banMsg",
      "banUser",
      "owner",
    ]),
    ...mapState("account", ["id"]),
    msg() {
      return this.messages[this.active[this.author]];
    },
    isMine() {
      return this.author === this.id;
    },
  },
  methods: {
    ...mapActions("room", ["voteMsg"]),
    ...mapMutations("room", ["setOption"]),
    showOption() {
      this.setOption(this.active[this.author]);
    },
  },
};
</script>

<style scoped>
.rd {
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #eee;
  color: #000;
  align-items: center;
  position: relative;
}
.rd-vote {
  width: 40px;
  margin: auto 20px;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.rd-mine {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
}
.rd-body {
  flex-grow: 1;
}
.rd-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 2rem;
}
.rd-option {
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 10;
}
</style>