<template>
  <div class="ac-main">
    <Header class="hd-top" />
    <div class="ac-container">
      <Settings v-if="token" />
      <Logister v-else />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Logister from "../components/logister/Logister";
import Settings from "../components/settings/Settings.vue";
import { mapState } from "vuex";
export default {
  name: "Account",
  props: ["code"],
  components: { Header, Logister, Settings },
  computed: {
    ...mapState("account", ["token"]),
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/card.css";
.ac-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ac-container {
  width: 100%;
  max-width: 960px;
  margin-top: 50px;
}
</style>