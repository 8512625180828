<template>
  <div>
    <PointBalance />
    <!-- <PointBuy /> -->
    <PointSell />
  </div>
</template>

<script>
import PointBalance from "../points/PointBalance.vue";
// import PointBuy from "../components/PointBuy.vue";
import PointSell from "../points/PointSell.vue";
export default {
  name: "SettingsBuyPoints",
  components: { PointBalance, PointSell },
};
</script>

<style scoped>
</style>