<template>
  <div class="footer">
    <div class="ft-link">
      <a href="https://beian.miit.gov.cn">鄂ICP备2022010252号-1</a>
    </div>
    <div class="ft-space"></div>

    <div class="ft-link">
      <a
        href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E9%2584%2582B2-20220700&type=xuke"
        >ICP证 鄂B2-20220700</a
      >
    </div>
    <div class="ft-space"></div>

    <div class="ft-link">
      <img :src="beian" class="ft-img" />
      <a
        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006234"
      >
        鄂公网安备42018502006234号
      </a>
    </div>
  </div>
</template>

<script>
import beian from "../../img/beian.png";
export default {
  name: "Footer",
  data() {
    return {
      beian: beian,
    };
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: solid 1px #eee;
}

a {
  color: #bbb;
}
.ft-space {
  width: 50px;
}
.ft-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.ft-img {
  scale: 0.8;
  opacity: 0.5;
}
</style>