<template>
  <div class="profile">
    <div class="card">
      <div class="card-header">用户名</div>
      <div class="card-body">
        {{ username }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">ID</div>
      <div class="card-body">
        {{ id }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">最后访问</div>
      <div class="card-body" v-if="location">
        <a :href="`/room/${location.id}`">{{ location.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import Card from "./Card";
export default {
  name: "UserProfile",
  // components: { Card },
  computed: {
    ...mapState("user", ["id", "username", "location"]),
  },
};
</script>

<style scoped>
/* @import "../css/init.css"; */
</style>