import Vue from "vue";
import { axios } from "./axios/axios";
import VueAxios from "vue-axios";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

Vue.use(VueAxios, axios);
import timeAgo from "./utils/timeAgo";

Vue.config.productionTip = false;
Vue.prototype.$appName = "群论";

Vue.filter("timeAgo", timeAgo);

const socket = io(
  location.origin.indexOf("8080") < 0
    ? location.origin
    : `http://${location.hostname}:5000`,
  { withCredentials: true, autoConnect: false }
);

Vue.use(VueSocketIOExt, socket, { store });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
