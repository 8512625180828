<template>
  <div class="menu">
    <div class="menu-left">
      <div class="menu-group">
        <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 0 }"
          @click="menu = 0"
        >
          登录/注册
        </div>
        <!-- <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 1 }"
          @click="menu = 1"
        >
          注册
        </div>
        <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 2 }"
          @click="menu = 2"
        >
          重置密码
        </div> -->
      </div>
    </div>
    <div class="menu-right">
      <!-- <LogisterLogin v-if="menu === 0" /> -->
      <RegisterAlipay />
      <!-- <LogisterReset v-else /> -->
    </div>
  </div>
</template>

<script>
import LogisterLogin from "./LogisterLogin";
import LogisterRegister from "./LogisterRegister";
import LogisterReset from "./LogisterReset";
import RegisterAlipay from "./RegisterAlipay";

export default {
  name: "Logister",
  components: {
    LogisterLogin,
    LogisterRegister,
    LogisterReset,
    RegisterAlipay,
  },
  data() {
    return {
      menu: 0,
    };
  },
};
</script>

<style scoped>
</style>