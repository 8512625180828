// function timeAgo(t) {
//   const diff = Math.floor(new Date().valueOf() / 1000) - t;
//   let qty, unit;
//   if (diff < 60) {
//     qty = Math.floor(diff);
//     unit = "秒";
//   } else if (diff < 60 * 60) {
//     qty = Math.floor(diff / 60);
//     unit = "分";
//   } else if (diff < 60 * 60 * 24) {
//     qty = Math.floor(diff / (60 * 60));
//     unit = "小时";
//   } else if (diff < 60 * 60 * 24 * 365) {
//     qty = Math.floor(diff / (60 * 60 * 24));
//     unit = "天";
//   } else {
//     qty = Math.floor(diff / (60 * 60 * 24 * 365));
//     unit = "年";
//   }
//   // if (qty > 1) {
//   //   out += "s";
//   // }
//   return `${qty}${unit}`;
// }

module.exports = function (t) {
  const diff = Math.floor(new Date().valueOf() / 1000) - t;
  let qty, unit;
  if (diff < 60) {
    qty = Math.floor(diff);
    unit = "秒";
  } else if (diff < 60 * 60) {
    qty = Math.floor(diff / 60);
    unit = "分";
  } else if (diff < 60 * 60 * 24) {
    qty = Math.floor(diff / (60 * 60));
    unit = "小时";
  } else if (diff < 60 * 60 * 24 * 365) {
    qty = Math.floor(diff / (60 * 60 * 24));
    unit = "天";
  } else {
    qty = Math.floor(diff / (60 * 60 * 24 * 365));
    unit = "年";
  }
  // if (qty > 1) {
  //   out += "s";
  // }
  return `${qty}${unit}`;
};
