function validateEmail(e) {
  const re = /\S+@\S+\.\S+/;
  return re.test(e);
}

function validatePassword(p) {
  const re = /^[a-zA-Z0-9]{8,}$/;
  return re.test(p);
}

function validateMobile(p) {
  const re = /^1\d{10}$/;
  return re.test(p);
}

module.exports = {
  validateEmail,
  validatePassword,
  validateMobile,
};
