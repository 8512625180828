<template>
  <div class="rl-frame">
    <div class="rl-icon" style="right: 35px" @click="getVoteShares">
      <IconRefresh />
    </div>

    <div class="card rl-card">
      <div class="card-header">本聊天室信息</div>
      <div class="card-body">
        <div class="rl-list">
          <li class="rl-item">
            <div class="rl-key">管理员</div>
            <div class="rl-value">
              <a :href="`/user/${owner}`">{{ username }}</a>
            </div>
          </li>
          <li class="rl-item">
            <div class="rl-key">开始</div>
            <div class="rl-value">{{ timeStart }}</div>
          </li>
          <li class="rl-item">
            <div class="rl-key">结束</div>
            <div class="rl-value">{{ timeEnd }}</div>
          </li>
          <li class="rl-item">
            <div class="rl-key">周期</div>
            <div class="rl-value">{{ cycle }}秒</div>
          </li>
          <li class="rl-item">
            <div class="rl-key">主播</div>
            <div class="rl-value">{{ onlives }}</div>
          </li>
          <li class="rl-item">
            <div class="rl-key">观众</div>
            <div class="rl-value">{{ online }}</div>
          </li>
        </div>
      </div>
    </div>

    <!-- <div class="card rl-card">
      <div class="card-header">积分贡献榜</div>
      <div class="card-body">
        <div class="rl-list">
          <li class="rl-item">
            <div class="rl-username">用户</div>
            <div class="rl-points">积分</div>
          </li>
          <li class="rl-item" v-for="user in contributors" :key="user.id">
            <div class="rl-username">
              <a :href="`/user/${user.id}`">
                {{ user.username }}
                <span v-if="user.id === id">(我)</span>
              </a>
            </div>
            <div class="rl-points">
              {{ user.total }}
            </div>
          </li>
        </div>
      </div>
    </div> -->

    <!-- <div class="card rl-card">
      <div class="card-header">积分收获榜</div>
      <div class="card-body">
        <div class="rl-list">
          <li class="rl-item">
            <div class="rl-username">用户</div>
            <div class="rl-votes">获赞</div>
            <div class="rl-points">积分</div>
          </li>
          <li class="rl-item" v-for="vote in votes" :key="vote.author">
            <div class="rl-username">
              <a :href="`/user/${vote.author}`">
                {{ vote.username }} <span v-if="vote.author === id">(我)</span>
              </a>
            </div>
            <div class="rl-votes">
              {{ vote.votes }}
            </div>
            <div class="rl-points">
              {{ Math.floor((vote.votes * totalPoints) / totalVotes) }}
            </div>
          </li>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import IconClose from "vue-material-design-icons/Close.vue";
import IconRefresh from "vue-material-design-icons/Refresh.vue";
import epoch from "../../../utils/epoch";
import dblDigit from "../../../utils/dblDigit";

export default {
  name: "RoomInfo",
  components: { IconClose, IconRefresh },
  data() {
    return {
      votes: [],
    };
  },
  computed: {
    ...mapState("room", [
      "rewards",
      "start",
      "end",
      "cycle",
      "owner",
      "username",
      "online",
      "lives",
    ]),
    onlives() {
      return Object.values(this.lives).filter((l) => l.online).length;
    },
    ...mapState("account", ["id"]),
    contributors() {
      return Object.values(
        this.rewards.reduce((a, c) => {
          if (a[c.user]) {
            a[c.user]["total"] += c.amount;
          } else {
            a[c.user] = {
              total: c.amount,
              id: c.user,
              username: c.username,
            };
          }
          return a;
        }, {})
      ).sort((a, b) => b.total - a.total);
    },
    totalPoints() {
      return this.rewards.reduce((a, c) => a + c.amount, 0);
    },
    totalVotes() {
      return this.votes.reduce((a, c) => a + c.votes, 0);
    },
    timeStart() {
      const time = new Date(this.start * 1000);
      return `${time.getMonth() + 1}月${time.getDate()}日 ${dblDigit(
        time.getHours()
      )}:${dblDigit(time.getMinutes())}`;
    },
    timeEnd() {
      const time = new Date(this.end * 1000);
      const now = epoch();
      const text = `${time.getMonth() + 1}月${time.getDate()}日 ${dblDigit(
        time.getHours()
      )}:${dblDigit(time.getMinutes())}`;
      return now > this.end ? text + "(已结束)" : text;
    },
  },
  methods: {
    ...mapMutations("room", ["setShowMore"]),
    getVoteShares() {
      this.axios
        .get(`/room/${this.$route.params.id}/points`)
        .then(({ data }) => {
          // console.log(data);
          if (data.msg === "OK") {
            this.votes = data.votes.sort((a, b) => b.votes - a.votes);
          }
        });
    },
  },
  mounted() {
    this.getVoteShares();
  },
};
</script>

<style scoped>
.rl-frame {
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* background-color: #fafaff; */
  position: relative;
  height: 100%;
  scrollbar-width: none;
  align-items: center;
  padding-top: 20px;
  position: relative;
  padding: 20px;
}
.rl-card {
  width: 100%;
}
.rl-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}
/* .rl-title {
  font-weight: 500;
  margin-bottom: 10px;
} */
.rl-list li:first-of-type {
  border-top: 1px #aaa solid;
}
.rl-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px #aaa solid;
  height: 30px;
}

.rl-points {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  font-weight: 600;
  font-size: 1rem;
}
.rl-votes {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.rl-username {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.rl-key {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.rl-value {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.rl-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
</style>