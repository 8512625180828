<template>
  <div>
    <div class="card">
      <div class="card-header">用户名</div>
      <div class="card-body">
        <div class="form-group">
          <input type="text" class="form-control" v-model="name" />
        </div>
        <div class="btn-group">
          <button class="btn-group-btn" @click="change" :disabled="status < 0">
            修改
          </button>
          <div class="btn-group-hint">
            <p v-if="status === 1">修改成功</p>
            <p v-else-if="status === -1">修改中...</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">id</div>
      <div class="card-body">
        {{ id }}
      </div>
    </div>
    <SettingsLogout />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SettingsLogout from "../settings/SettingsLogout.vue";
export default {
  name: "SettingsUsername",
  components: { SettingsLogout },
  data() {
    return {
      name: "",
      status: 0,
    };
  },
  computed: {
    ...mapState("account", ["id", "username"]),
  },
  methods: {
    ...mapMutations("account", ["setUsername", "logout"]),
    change() {
      this.status = -1;
      this.axios
        .post("/user/username", { username: this.name }, { auth: true })
        .then(({ data }) => {
          if (data.msg === "OK") {
            this.status = 1;
            this.setUsername(data.username);
            this.name = data.username;
          }
        });
    },
  },
  mounted() {
    this.name = this.username;
  },
};
</script>

<style scoped>
/* @import "../css/form.css"; */
/* @import "../css/card.css"; */
</style>