<template>
  <div>
    <div class="card">
      <div class="card-header">当前号码</div>
      <div class="card-body">
        {{ number || "无" }}
      </div>
    </div>
    <div class="card">
      <div class="card-header">修改手机号</div>
      <div class="card-body">
        <div class="form-group">
          <label class="form-label">旧号码</label>

          <input type="password" class="form-control" v-model="oldp" />
        </div>
        <div class="form-group">
          <label class="form-label">新号码</label>

          <input type="password" class="form-control" v-model="newp" />
        </div>
        <div class="form-group">
          <label class="form-label">新号码确认</label>

          <input type="password" class="form-control" v-model="newp1" />
        </div>
        <div class="btn-group">
          <button class="btn-group-btn" @click="change" :disabled="status < 0">
            修改
          </button>
          <div class="btn-group-hint">
            <p v-if="status === 1">修改成功</p>
            <p v-else-if="status === 2">旧密码错误</p>
            <p v-else-if="status === 3">新密码不符合要求</p>
            <p v-else-if="status === 4">新密码两次输入不同</p>
            <p v-else-if="status === -1">修改中...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SettingsMobile",
  data() {
    return {
      number: null,
      oldp: null,
      newp: null,
      newp1: null,
      status: 0,
    };
  },
  computed: {
    ...mapState("account", ["mobile"]),
  },
  methods: {
    change() { },
  },
};
</script>

<style></style>