<template>
  <div class="tp">
    <div class="tp-mine" v-if="isMine">我</div>
    <div
      class="tp-checkbox"
      :class="{ 'tp-checkbox-checked': checked }"
      @click="() => voteMsg(active[author])"
    >
      <IconThumbUp />
      <span class="tp-votes">
        {{ novotes(active[author]) }}
      </span>
    </div>
    <template v-if="owner === id">
      <div class="tp-toggle" @click="showOption">
        <div v-if="option === active[author]"><IconMenuUp /></div>
        <div v-else><IconMenuDown /></div>
      </div>
      <div class="tp-option" v-if="option === active[author]">
        <MsgOption :author="author" />
      </div>
    </template>

    <div class="tp-body">
      <ShowExcerpt :height="136">
        {{ msg && msg.text }}
      </ShowExcerpt>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import IconThumbUp from "vue-material-design-icons/ThumbUp.vue";
import IconMenuUp from "vue-material-design-icons/MenuUp.vue";
import IconMenuDown from "vue-material-design-icons/MenuDown.vue";
import ShowExcerpt from "../ShowExcerpt";
import MsgOption from "./MsgOption.vue";
// import timeAgo from "../../utils/timeAgo";
// import epoch from "../../utils/epoch";

export default {
  name: "MsgTop",
  props: ["author"],
  components: {
    ShowExcerpt,
    IconThumbUp,
    MsgOption,
    IconMenuUp,
    IconMenuDown,
  },

  computed: {
    ...mapGetters("room", ["novotes"]),
    ...mapState("room", [
      "messages",
      "active",
      "option",
      "banMsg",
      "banUser",
      "owner",
      "votes",
    ]),
    ...mapState("account", ["id"]),
    msg() {
      return this.messages[this.active[this.author]];
    },
    isMine() {
      return this.author === this.id;
    },
    checked() {
      return this.id && this.votes[this.id] === this.active[this.author];
    },
  },
  methods: {
    ...mapActions("room", ["voteMsg"]),
    ...mapMutations("room", ["setOption"]),
    showOption() {
      this.setOption(this.active[this.author]);
    },
  },
};
</script>

<style scoped>
.tp-checkbox {
  width: 40px;
  margin: auto 20px;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tp-checkbox-checked {
  color: rgba(0, 0, 0, 1);
}
.tp {
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #eee;
  color: #000;
  align-items: center;
  position: relative;
}

.tp-mine {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
}
.tp-body {
  flex-grow: 1;
}
.tp-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 2rem;
}
.tp-option {
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 10;
}
</style>