<template>
  <div>
    <div class="card">
      <div class="card-header">联系方式</div>
      <div class="card-body">
        邮箱: qunlunapp@163.com <br />
        微信: qunlunapp （加微信群可获得最新聊天室信息提醒）
      </div>
    </div>
    <div class="card">
      <div class="card-header">证照信息</div>
      <div class="card-body">
        <a href="https://beian.miit.gov.cn">鄂ICP备2022010252号-1</a><br />
        <a
          href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E9%2584%2582B2-20220700&type=xuke"
          >ICP证 鄂B2-20220700</a
        ><br />
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006234"
        >
          鄂公网安备42018502006234号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
};
</script>

<style scoped>
</style>