<template>
  <div>
    <h4 style="margin: 20px 0">
      请点击右上角的“三个点”，然后选择“在浏览器打开”
    </h4>
    <span style="color: rgba(0, 0, 0, 0.3)">（微信里无法使用支付宝登录）</span>
  </div>
</template>

<script>
export default {
  name: "RedirectWechat",
  mounted() {
    if (navigator.userAgent.indexOf("MicroMessenger") < 0) {
      location.assign("/account");
    }
  },
};
</script>

<style>
</style>