<template>
  <div class="ba">
    <div class="ba-tab tab">
      <div
        class="ba-tab-item tab-item"
        :class="{ 'tab-item-active': tab === 0 }"
        @click="() => switchTab(0)"
      >
        已提交
      </div>
      <div
        class="ba-tab-item tab-item"
        :class="{ 'tab-item-active': tab === 1 }"
        @click="() => switchTab(1)"
      >
        已通过
      </div>
      <div
        class="ba-tab-item tab-item"
        :class="{ 'tab-item-active': tab === 2 }"
        @click="() => switchTab(2)"
      >
        已封禁
      </div>
    </div>

    <div class="ba-list">
      <div class="ba-item" v-for="b in list" :key="b.id">
        <div class="ba-left">
          <div class="ba-text">{{ b.text }}</div>
          <div class="ba-username">
            <a :href="`/user/${b.user}`">{{ b.username }}</a>
          </div>
        </div>
        <div class="ba-right">
          <div class="ba-approve" @click="() => decide(b.id)">
            {{ btnText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerApprove",
  data() {
    return {
      tab: 0,
      list: [],
    };
  },
  computed: {
    btnText() {
      let txt;
      switch (this.tab) {
        case 1:
          txt = "封禁";
          break;

        default:
          txt = "通过";
          break;
      }
      return txt;
    },
  },
  methods: {
    switchTab(tab) {
      this.tab = tab;
      this.refresh();
    },
    refresh() {
      let url;
      switch (this.tab) {
        case 1:
          url = "/banner/approved";
          break;
        case 2:
          url = "/banner/denied";
          break;
        default:
          url = "/banner/submitted";
          break;
      }
      this.axios.get(url).then(({ data }) => {
        if (data.msg === "OK") {
          this.list = data.banners;
        }
      });
    },
    decide(id) {
      let url;
      switch (this.tab) {
        case 1:
          url = "/admin/banner/deny";
          break;
        default:
          url = "/admin/banner/approve";
          break;
      }
      this.axios.post(url, { id }, { auth: true }).then(({ data }) => {
        if (data.msg === "OK") {
          this.list = this.list.filter((b) => b.id !== id);
        }
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
@import "../../css/tab.css";
@import "../../css/banner.css";

.ba-tab-item {
  /* margin-bottom: 10px; */
  width: 33.33%;
}
.ba-item {
  margin-top: 10px;
}
</style>