<template>
  <div class="vp-frame">
    <video ref="videoPlayer" class="video-js vp-canvas"></video>

    <div
      class="vp-redirect"
      @click="redirect"
      title="打开抖音直播间"
      v-if="focus && token"
    >
      抖
    </div>
  </div>
</template>
  
<script>
import videojs from "video.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "VideoPlayer",
  data() {
    return {
      player: null,
      modal: null,
    };
  },
  watch: {
    liveSources(v) {
      // this.axios
      //   .get(v[0].src, {
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   })
      //   .then((data) => {
      //     console.log(data);
      //   });
      if (this.player) {
        this.player.pause();
        this.player.src(v);
        this.player.load();
      } else {
        videojs.hook("beforeerror", (player, err) => {
          if (err && err.code == 4) {
            // player.pause();
            this.delLive();
            // this.modal = player.createModal("该主播已下播");
            // setTimeout(() => {
            //   this.modal.close();
            // }, 2000);
          }
          if (err && err.code !== 4) {
            console.log("other error");
          }
          return null;
        });
        // videojs.hook("error", (player, err) => {
        //   console.log("error", err);
        //   return null;
        // });

        const player = videojs(
          this.$refs.videoPlayer,
          {
            autoplay: true,
            controls: true,
            sources: v,
            fill: true,
          },
          () => {
            // this.player.log("onPlayerReady");
            player.tech().on("retryplaylist", (e, data) => {
              this.delLive();
            });
          }
        );
        this.player = player;
      }
    },
  },
  computed: {
    ...mapState("account", ["token"]),
    ...mapState("room", ["focus", "lives"]),
    ...mapGetters("room", ["liveSources"]),
  },
  methods: {
    ...mapActions("room", ["delLive"]),
    redirect() {
      const url = `https://v.douyin.com/${this.lives[this.focus].shareid}`;
      window.open(url);
    },
  },
  mounted() {},
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>


<style scoped>
@import "video.js/dist/video-js.css";
.vp-frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.vp-canvas {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.vp-redirect {
  position: absolute;
  bottom: 40px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px black solid;
  background-color: white;
}
</style>