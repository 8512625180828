<template>
  <div class="screen">
    <div class="content" style="padding: 20px">
      <UserProfile v-if="tab === 0" style="width: 100%" />
    </div>
    <div class="footer">
      <ul class="tab-list">
        <li class="tab-item" @click="back">
          <div class="tab-icon"><IconArrowLeft /></div>
          <div class="tab-text">首页</div>
        </li>

        <li
          class="tab-item"
          @click="tab = 0"
          :class="{ 'tab-active': tab === 0 }"
        >
          <div class="tab-text">资料</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import IconArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import UserProfile from "../components/user/UserProfile";
// import RegisterAlipay from "../components/logister/RegisterAlipay.vue";
// import SettingsUsername from "../components/settings/SettingsUsername.vue";
// import SettingsPoints from "../components/settings/SettingsPoints.vue";
// import SettingsBanner from "../components/settings/SettingsBanner.vue";

export default {
  name: "User",
  components: {
    IconArrowLeft,
    UserProfile,
    // RegisterAlipay,
    // SettingsUsername,
    // SettingsPoints,
    // SettingsBanner,
    // RoomListMobile,
    // RoomTabs,
    // Logister,
    // Settings,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapState("account", ["token"]),
  },
  methods: {
    ...mapActions("user", ["initUser"]),
    back() {
      location.assign("/");
    },
  },

  mounted() {
    this.initUser(this.$route.params.id);
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/footer-nav.css";
</style>
