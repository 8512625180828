<template>
  <div>
    <div class="card">
      <div class="card-header">手机</div>
      <div class="card-body">
        <div class="mobile-inline">
          <input
            type="text"
            class="form-control"
            v-model="mobile"
            ref="mobile"
          />

          <button
            class="btn mobile-btn"
            @click="getCode"
            v-if="eta < 0"
            :disabled="status"
          >
            获取验证码
          </button>

          <button class="btn mobile-btn" v-else>{{ eta }}秒后重试</button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">验证码</div>
      <div class="card-body">
        <input type="text" class="form-control" v-model="code" ref="code" />
      </div>
    </div>
    <div class="card">
      <div class="card-header">新密码</div>
      <div class="card-body">
        <input
          type="password"
          class="form-control"
          v-model="password1"
          ref="password1"
        />
        <span class="password-hint"
          >密码由8个以上字符(A-Za-z0-9)组成，其中至少有1个大写字母1个小写字母和1个数字</span
        >
      </div>
    </div>
    <div class="card">
      <div class="card-header">重复新密码</div>
      <div class="card-body">
        <input
          type="password"
          class="form-control"
          v-model="password2"
          ref="password2"
        />
      </div>
    </div>
    <div class="login-submit">
      <button class="btn" @click="submit" :disabled="status">提交</button>
    </div>
    <div class="login-hint">
      <span v-if="hint === 1">手机号码格式不正确</span>
      <span v-else-if="hint === 2">密码格式不正确</span>
      <span v-else-if="hint === 3">两次密码不相同</span>
      <span v-else-if="hint === 4">验证码格式不对（6位数字）</span>
      <span v-else-if="hint === 5">验证码不存在</span>
      <span v-else-if="hint === 6">验证码已过期</span>
      <span v-else-if="hint === 7">验证码不正确</span>
      <span v-else-if="hint === 8">手机号不存在</span>
      <span v-else-if="hint === 9">修改成功，请登录</span>
    </div>
  </div>
</template>

<script>
import { validateMobile, validatePassword } from "../../../utils/validators";
import epoch from "../../../utils/epoch";

export default {
  name: "LogisterReset",
  data() {
    return {
      hint: 0,
      mobile: "",
      code: "",
      sent: null,
      eta: -1,
      timer: null,
      password1: "",
      password2: "",
      status: false,
    };
  },
  watch: {
    code(v) {
      if (v.length > 0) {
        const last = parseInt(v.slice(-1)).toString();
        if (!(last >= 0 && last <= 9) || v.length > 6) {
          this.code = v.slice(0, -1);
        }
      }
    },
    mobile(v) {
      if (v.length > 0) {
        const last = parseInt(v.slice(-1)).toString();
        if (!(last >= 0 && last <= 9) || v.length > 11) {
          this.mobile = v.slice(0, -1);
        }
      }
    },
  },
  methods: {
    getCode() {
      if (!validateMobile(this.mobile)) {
        this.hint = 1;
        this.$refs.mobile.focus();
        return;
      }
      this.hint = 0;
      this.status = true;
      this.axios
        .post("/user/reset/code", { mobile: this.mobile })
        .then(({ data }) => {
          this.status = false;
          if (data.msg === "OK") {
            this.sent = epoch();
            this.timer = setInterval(() => {
              this.eta = this.sent + 120 - epoch();
              if (this.eta < 0) {
                clearInterval(this.timer);
              }
            }, 1000);
          } else if (data.msg === "NO_USER") {
            this.hint = 8;
          } else {
            this.hint = 9;
          }
        });
    },
    submit() {
      if (!validateMobile(this.mobile)) {
        this.hint = 1;
        this.$refs.mobile.focus();
        return;
      }
      if (!validatePassword(this.password1)) {
        this.hint = 2;
        this.$refs.password1.focus();
        return;
      }
      if (this.password1 !== this.password2) {
        this.hint = 3;
        this.$refs.password2.focus();
        return;
      }
      if (this.code.length < 6) {
        this.hint = 4;
        this.$refs.code.focus();
      }

      this.hint = 0;
      this.status = true;
      this.axios
        .post("/user/reset", {
          mobile: this.mobile,
          code: this.code,
          password: this.password1,
        })
        .then(({ data }) => {
          this.status = false;
          switch (data.msg) {
            case "OK":
              this.hint = 9;
              break;
            case "NO_CODE":
              this.hint = 5;
              this.$refs.code.focus();
              break;
            case "CODE_EXPIRED":
              this.hint = 6;
              this.$refs.code.focus();
              break;
            case "CODE_MISMATCH":
              this.hint = 7;
              this.$refs.code.focus();
              break;
            case "NO_USER":
              this.hint = 8;
              this.$refs.mobile.focus();
              break;
            case "MOBILE_INVALID":
              this.hint = 1;
              this.$refs.mobile.focus();
              break;
            case "PASSWORD_INVALID":
              this.hint = 2;
              this.$refs.password1.focus();
              break;
          }
        });
    },
  },
};
</script>

<style scoped>
.mobile-inline {
  display: flex;
  flex-direction: row;
}
.mobile-btn {
  width: 150px;
  margin-left: 10px;
}
.password-hint {
  font-size: 80%;
  opacity: 60%;
}
.login-submit {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
}
.login-hint {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  color: rgba(255, 0, 0, 0.5);
}
</style>