<template>
  <div>
    <div v-if="redirect">
      <h4 style="margin: 20px 0">
        请点击右上角的“三个点”，然后选择“用浏览器打开”
      </h4>
      <span style="color: rgba(0, 0, 0, 0.3)"
        >（支付宝浏览器无法提供最佳体验，请使用系统浏览器）</span
      >
    </div>
    <div v-else>
      <div>支付宝登录</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginAlipay",
  data() {
    return {
      msg: "",
      redirect: false,
    };
  },
  // methods: {},
  mounted() {
    // this.msg = location;
    if (navigator.userAgent.indexOf("AlipayClient") < 0) {
      if (this.$route.query.auth_code) {
        this.axios
          .post(`/user/login/alipay`, { code: this.$route.query.auth_code })
          .then(({ data }) => {
            if (data.msg === "OK") {
              // console.log(data);
              localStorage.setItem("gt-token", data.token);
              localStorage.setItem("gt-username", data.username);
              localStorage.setItem("gt-id", data.id);
              localStorage.setItem("gt-points", data.points);
              localStorage.setItem("gt-mobile", data.mobile);
              localStorage.setItem("gt-alipay", data.alipay);
              location = location.origin;
            }
          })
          .catch((err) => {});
      } else {
        alert("Auth code empty.");
      }
    } else {
      this.redirect = true;
    }
  },
};
</script>

<style scoped>
</style>