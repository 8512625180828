<template>
  <div class="excerpt">
    <div
      ref="text"
      class="ex-text"
      :style="showAll ? '' : `max-height: ${height}px; overflow-y: hidden;`"
    >
      <slot></slot>
    </div>
    <div class="ex-show" v-if="showShowAll" @click="showAll = true">
      显示全部
    </div>
  </div>
</template>


<script>
export default {
  name: "ShowExcerpt",
  props: ["height"],
  data() {
    return {
      clientHeight: 0,
      showAll: false,
    };
  },
  computed: {
    showShowAll() {
      return this.clientHeight === this.height && !this.showAll;
    },
  },
  watch: {
    content() {
      this.showAll = false;
      this.$nextTick(() => {
        this.clientHeight = this.$refs.text.clientHeight;
      });
    },
  },
  mounted() {
    this.clientHeight = this.$refs.text.clientHeight;
  },
};
</script>

<style scoped>
.excerpt {
  display: block;
}
.ex-text {
  font-size: 1rem;
  text-align: left;
}
.ex-show {
  float: right;
  color: gray;
  margin: 5px;
  font-size: 80%;
  cursor: pointer;
}
</style>