import Vue from "vue";
import { axios } from "../axios/axios";
import epoch from "../utils/epoch";
import router from "../router/index";

const state = {
  id: null,
  info: null,
  voted: false,
  start: null,
  end: null,
  last: null,
  cycle: null,
  owner: null,
  online: null,
  random: [],
  history: [],
  pa: [],
  option: null,
  lives: {},
  sources: [],
  focus: null,

  messages: {},
  active: {},
  votes: {},
  views: {},

  myBannedMsg: {},
  allBannedMsg: {},
  isBanned: false,
  allBannedUser: {},

  rewards: [],
  banner: null,
  showMore: 1,
  showModal: 0,
  showRandom: false,
  writing: false,
  timer: null,
  statusAddLive: 0,
};

const getters = {
  myMsgId: (state, getters, rootState) => state.active[rootState.account.id],
  novotes: (state) => (id) =>
    Object.values(state.votes).filter((mid) => mid === id).length,
  ranked: (state) => {
    const count = Object.values(state.votes).reduce((a, c) => {
      if (!a[c]) {
        a[c] = 0;
      }
      a[c]++;
      return a;
    }, {});
    return Object.keys(state.active)
      .map((a) => ({
        author: a,
        count: count[state.active[a]] || 0,
      }))
      .sort((a, b) => b.count - a.count)
      .map((m) => m.author);
  },
  isOwner: (state, getters, rootState) => state.owner === rootState.account.id,
  historyMsgPA: (state) =>
    state.history
      .filter(
        (m) =>
          !state.allBannedMsg[m.message] &&
          !state.allBannedUser[state.messages[m.message].author]
      )
      .sort((a, b) => a.time - b.time)
      .map((m) =>
        state.messages[m.message].ref == "pa"
          ? {
              type: "A",
              id: m.message,
              time: m.time,
            }
          : {
              type: "M",
              id: m.message,
              time: m.time,
              votes: m.votes,
            }
      ),
  liveSources: (state) => state.sources[state.focus],

  sortedLives: (state) => {
    const count = Object.values(state.views).reduce((a, c) => {
      if (!a[c]) {
        a[c] = 0;
      }
      a[c]++;
      return a;
    }, {});
    return Object.keys(state.lives)
      .filter((k) => state.lives[k].online)
      .map((a) => ({
        count: count[a] || 0,
        ...state.lives[a],
      }))
      .sort((a, b) => {
        const count = b.count - a.count;
        const time = b.time - a.time;
        return count == 0 ? time : count;
      });
  },
  liveViews: (state) => (streamer) =>
    Object.values(state.views).filter((id) => id === streamer).length,
};
const mutations = {
  setModal(state, s) {
    state.showModal = s;
  },
  // setHidden(state, id) {
  //   Vue.set(state.hidden, id, true);
  // },
  // delHidden(state, id) {
  //   Vue.delete(state.hidden, id);
  // },
  // setBanUser(state, u) {
  //   Vue.set(state.allBannedUser, u, true);

  // },
  // delBanUser(state, id) {
  //   Vue.delete(state.banUser, id);
  // },
  // setBanMsg(state, id) {
  //   Vue.set(state.banMsg, id, true);
  // },
  // delBanMsg(state, id) {
  //   Vue.delete(state.banMsg, id);
  // },

  setOption(state, m) {
    if (state.option === m) {
      state.option = null;
    } else {
      state.option = m;
    }
  },
  setWriting(state, w) {
    state.writing = w;
  },
  startCycle(state) {
    const now = epoch();
    const milepoch = new Date().valueOf();
    if (now < state.start) {
      setTimeout(() => {
        cycleBanners();
        setInterval(() => {
          cycleBanners();
        }, state.cycle * 1000);
      }, state.start * 1000 - milepoch);
    } else {
      setTimeout(() => {
        cycleBanners();
        setInterval(() => {
          cycleBanners();
        }, state.cycle * 1000);
      }, state.start * 1000 + state.cycle * Math.ceil((now - state.start) / state.cycle) * 1000 - milepoch);
    }

    const cycleBanners = function () {
      // const now = epoch();
      // const start =
      //   state.last + state.cycle * Math.ceil((now - state.last) / state.cycle);
      // const diff = start * 1000 - new Date().valueOf();

      const rewards = state.rewards.sort((a, b) => b.amount - a.amount);
      const total = rewards.reduce((a, c) => a + c.amount, 0);

      rewards.reduce((a, c) => {
        setTimeout(() => {
          state.banner = c;
        }, a);

        // if (first) {
        //   if (a + diff - state.cycle * 1000 >= 0) {
        //     setTimeout(() => {
        //       state.banner = c;
        //     }, a + diff - state.cycle * 1000);
        //     if (
        //       a +
        //         diff -
        //         state.cycle * 1000 -
        //         (state.cycle * 1000 * rewards[i - 1].amount) / total <
        //       0
        //     ) {
        //       state.banner = rewards[i - 1].banner;
        //     }
        //   }
        // }

        a += (state.cycle * 1000 * c.amount) / total;
        return a;
      }, 0);
    };
  },

  setShowMore(state, r) {
    state.showMore = r;
  },
  clearMsg(state) {
    ////// clear the msgs that are useless.
  },
  setStatusAddLive(state, s) {
    state.statusAddLive = s;
  },

  SOCKET_UNAUTHED() {
    alert("该操作需要登陆");
    localStorage.removeItem("gt-id");
    localStorage.removeItem("gt-username");
    localStorage.removeItem("gt-token");
    localStorage.removeItem("gt-points");
    location.assign("/");
  },

  SOCKET_END(state) {
    alert("聊天已结束，获得的积分可在账号页面提现。");
    location.assign(`/archive/${state.id}`);
  },
  SOCKET_NEW_HISTORY(state, msgs) {
    state.history.push(...msgs);
  },
  SOCKET_NEW_PA(state, msg) {
    state.pa.push(msg);
  },

  SOCKET_ONLINE(state, o) {
    state.online = o;
  },
  SOCKET_NEW_LIVE(state, l) {
    Vue.set(state.lives, l.userid, {
      userid: l.userid,
      username: l.username,
      avatar: l.avatar,
      shareid: l.shareid,
      time: l.time,
      online: true,
    });
    Vue.set(
      state.sources,
      l.userid,
      JSON.parse(l.hls).map((url) => ({
        src: url,
        type: "application/x-mpegURL",
      }))
    );
    state.statusAddLive = 1;
  },
  SOCKET_LIVE_INVALID(state) {
    state.statusAddLive = 2;
  },
  SOCKET_LIVE_EXISTED(state) {
    state.statusAddLive = 3;
  },
  SOCKET_LIVE_OFFLINE(state, userid) {
    const info = state.lives[userid];
    Vue.set(state.lives, userid, {
      ...info,
      online: false,
    });
    state.focus = null;
  },
  // SOCKET_BAN_MESSAGE(state, o) {
  //   Vue.set(state.banMsg, o, true);
  // },
  // SOCKET_BAN_USER(state, o) {
  //   Vue.set(state.banUser, o, true);
  // },
  // SOCKET_UNBAN_MESSAGE(state, o) {
  //   Vue.set(state.banMsg, o, false);
  // },
  // SOCKET_UNBAN_USER(state, o) {
  //   // Vue.set(state.banUser, o, false);
  //   Vue.delete(state.banUser, o);
  // },
};
const actions = {
  delLive({ state, rootState }) {
    if (rootState.account.token) {
      this._vm.$socket.client.emit("LIVE_CLOSED", {
        room: state.id,
        token: rootState.account.token,
        userid: state.focus,
        shareid: state.lives[state.focus].shareid,
      });
    }
  },
  setFocus({ state, rootState }, f) {
    if (state.focus !== f) {
      state.focus = f;
      if (rootState.account.token) {
        this._vm.$socket.client.emit("CHANGE_VIEW", {
          room: state.id,
          token: rootState.account.token,
          userid: f,
        });
      }
    }
  },
  getMessage({ state }, id) {
    axios.get(`/room/${state.id}/msg/${id}`).then(({ data }) => {
      if (data.msg === "OK") {
        data.msgs.forEach((m) => {
          Vue.set(state.messages, m.id, m);
        });
      }
    });
  },
  socket_connected({ state, rootState, dispatch }) {
    if (!router.currentRoute.path.includes("room")) {
      return;
    }
    state.id = router.currentRoute.params.id;

    this._vm.$socket.client.emit("ENTER", {
      room: state.id,
      token: rootState.account.token,
    });
    dispatch("initRoom", state.id);
  },

  socket_newReward({ state }, r) {
    state.rewards.push(r);
  },

  socket_banUser({ state, rootState }, u) {
    Vue.set(state.allBannedUser, u.id, u.username);
    if (u.id === rootState.account.id) {
      state.isBanned = true;
    }
  },
  socket_unbanUser({ state, rootState }, u) {
    Vue.delete(state.allBannedUser, u);
    if (u === rootState.account.id) {
      state.isBanned = false;
    }
  },
  socket_banMessage({ state, rootState }, m) {
    Vue.set(state.allBannedMsg, m.msg, true);
    if (m.user === rootState.account.id) {
      Vue.set(state.myBannedMsg, m.msg, true);
    }
  },
  socket_unbanMessage({ state, rootState }, m) {
    Vue.delete(state.allBannedMsg, m.msg);
    if (m.user === rootState.account.id) {
      Vue.delete(state.myBannedMsg, m.msg, true);
    }
  },

  socket_newCycle({ dispatch, state, commit }, o) {
    // console.log(o);
    state.votes = {};
    state.voted = false;
    state.last = o.time;
    state.online = o.online;
    dispatch("refill", true);
    commit("clearMsg");
  },
  socket_newVote({ state, rootState }, vote) {
    // console.log(vote);
    Vue.set(state.votes, vote.user, vote.msg);
    if (vote.user === rootState.account.id) {
      state.voted = true;
    }
  },
  socket_liveView({ state, rootState }, view) {
    Vue.set(state.views, view.viewer, view.streamer);
    // if (vote.user === rootState.account.id) {
    //   state.voted = true;
    // }
  },
  socket_newMessage({ state, dispatch }, msg) {
    // console.log(msg);
    Vue.set(state.messages, msg.id, msg);
    if (msg.ref !== "pa") {
      Vue.set(state.active, msg.author, msg.id);
    }
    dispatch("refill", false);
  },

  voteMsg({ state, rootState }, id) {
    const now = epoch();
    if (now < state.start) {
      return alert(`聊天${state.start - now}秒后开始`);
    }
    if (now > state.end) {
      return alert(`聊天已结束`);
    }
    const author = state.messages[id].author;
    if (author === rootState.account.id) {
      return alert(`不能点赞自己的留言`);
    }
    this._vm.$socket.client.emit("VOTE_MESSAGE", {
      token: rootState.account.token,
      room: state.id,
      msg: id,
    });
  },

  // enter({ state }, id) {
  //   axios.post("/user/enter", { room: id }, { auth: true }).then(({ data }) => {
  //     // console.log(data);
  //   });
  // },
  refill({ state, rootState }, force) {
    // const users = Object.keys(state.active).filter(
    //   (u) => u !== rootState.account.id
    // );
    const users = Object.keys(state.active);
    if (force) {
      state.random.length = 0;
    }
    const rest = users
      .filter((u) => !state.random.includes(u))
      .map((id) => ({ id, rand: Math.random() }))
      .sort((a, b) => a.rand - b.rand)
      .map((e) => e.id);
    state.random.push(...rest);
  },

  initRoom({ state, commit, dispatch, rootState }, id) {
    axios.get(`/room/active/${id}`).then(({ data }) => {
      console.log(data);
      switch (data.msg) {
        case "REDIRECT":
          location.assign(`/archive/${data.id}`);
          break;
        case "OK":
          state.info = data.room;
          state.last = data.room.last;
          state.cycle = data.room.cycle;
          state.start = data.room.start;
          state.end = data.room.end;
          state.owner = data.room.owner;
          state.username = data.room.username;
          data.lives.forEach((l) => {
            Vue.set(state.lives, l.userid, {
              userid: l.userid,
              username: l.username,
              avatar: l.avatar,
              shareid: l.shareid,
              time: l.time,
              online: l.online,
            });
          });

          state.sources = data.lives.reduce((a, c) => {
            a[c.userid] = JSON.parse(c.hls).map((url) => ({
              src: url,
              type: "application/x-mpegURL",
            }));
            return a;
          }, {});

          data.messages.forEach((m) => {
            Vue.set(state.messages, m.id, m);
          });
          data.active.forEach((m) => {
            if (m.ref !== "pa") {
              Vue.set(state.active, m.author, m.message);
            }
          });
          data.votes.forEach((m) => {
            Vue.set(state.votes, m.voter, m.message);
            if (m.voter === rootState.account.id) {
              state.voted = true;
            }
          });
          data.views.forEach((v) => {
            Vue.set(state.views, v.viewer, v.streamer);
          });
          data.ban.forEach((b) => {
            if (b.type === "M") {
              Vue.set(state.allBannedMsg, b.id, true);
              if (b.ref === rootState.account.id) {
                Vue.set(state.myBannedMsg, b.id, true);
              }
            }
            if (b.type === "U") {
              Vue.set(state.allBannedUser, b.id, b.username);
              if (b.id === rootState.account.id) {
                state.isBanned = true;
              }
            }
          });
          state.history = data.history;
          state.pa = data.pa;
          state.rewards = data.rewards;
          dispatch("refill", true);
          commit("startCycle");
          // setInterval(() => {
          //   commit("startBanners", false);
          // }, state.cycle * 1000);

          break;
        case "NO_ROOM":
          alert("房间不存在");
          location.assign("/");
          break;
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
