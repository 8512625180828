<template>
  <div class="main">
    <Header class="hd-top" />
    <div class="container menu">
      <div class="menu-left">
        <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 0 }"
          @click="menu = 0"
        >
          账号
        </div>
      </div>
      <div class="menu-right">
        <UserProfile v-if="menu === 0" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import UserProfile from "../components/user/UserProfile";
// import UserMessages from "../components/user/UserMessages";
import { mapActions } from "vuex";
export default {
  name: "User",
  components: { Header, UserProfile },
  data() {
    return {
      menu: 0,
    };
  },
  methods: {
    ...mapActions("user", ["initUser"]),
  },
  mounted() {
    this.initUser(this.$route.params.id);
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/desktop.css";
</style>