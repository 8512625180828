<template>
  <div class="opt-main">
    <!-- <div class="opt-title">
      <div class="opt-title-item" :class="{ 'opt-title-item-active': shuffle }">
        <IconShuffle class="opt-icon" />
        随机取样
      </div>
      <div
        class="opt-title-item"
        :class="{ 'opt-title-item-active': !shuffle }"
      >
        <IconOrder class="opt-icon" />
        票数降序
      </div>
    </div> -->

    <div class="opt-rand" v-if="shuffle">
      <MsgRandom v-for="m in unbannedRandom" :key="m" :author="m" />
    </div>
    <div class="opt-rank" v-else>
      <MsgTop v-for="m in unbannedRanked" :key="m" :author="m" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IconShuffle from "vue-material-design-icons/ShuffleVariant.vue";
import IconOrder from "vue-material-design-icons/OrderNumericDescending.vue";
import MsgTop from "./MsgTop.vue";
import MsgRandom from "./MsgRandom.vue";

export default {
  name: "Current",
  components: { MsgTop, MsgRandom, IconShuffle, IconOrder },
  data() {
    return {};
  },
  computed: {
    ...mapState("room", [
      "voted",
      "random",
      "allBannedUser",
      "allBannedMsg",
      "active",
    ]),
    ...mapGetters("room", ["ranked"]),
    ...mapState("account", ["id"]),
    shuffle() {
      return !this.voted && !!this.id;
    },
    unbannedRanked() {
      return this.ranked.filter(
        (a) => !this.allBannedUser[a] && !this.allBannedMsg[this.active[a]]
      );
    },
    unbannedRandom() {
      return this.random.filter(
        (a) => !this.allBannedUser[a] && !this.allBannedMsg[this.active[a]]
      );
    },
  },
};
</script>

<style scoped>
.opt-main {
  padding: 20px;
  background-color: #fff8f8;
  width: 100%;
  height: 100%;
}
.opt-rand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.opt-rank {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}
.opt-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #eee;
  padding: 3px;
}
.opt-title-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  border-radius: 5px;
}
.opt-title-item-active {
  background-color: #ccc;
}
.opt-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
</style>