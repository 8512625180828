<template>
  <div class="menu">
    <div class="menu-left">
      <div class="menu-group">
        <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 0 }"
          @click="menu = 0"
        >
          账号
        </div>
        <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 1 }"
          @click="menu = 1"
        >
          积分
        </div>
        <!-- <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 2 }"
          @click="menu = 2"
        >
          手机
        </div> -->
        <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 3 }"
          @click="menu = 3"
        >
          标语
        </div>
        <!-- <div
          class="menu-item"
          :class="{ 'menu-item-active': menu === 4 }"
          @click="menu = 4"
        >
          密码
        </div> -->
      </div>
    </div>
    <div class="menu-right">
      <SettingsUsername v-if="menu === 0" />
      <SettingsMobile v-else-if="menu === 2" />
      <SettingsPassword v-else-if="menu === 4" />
      <SettingsBanner v-else-if="menu === 3" />
      <SettingsPoints v-else-if="menu === 1" />
    </div>
  </div>
</template>

<script>
import SettingsUsername from "./SettingsUsername.vue";
import SettingsPassword from "./SettingsPassword.vue";
import SettingsPoints from "./SettingsPoints.vue";
import SettingsBanner from "./SettingsBanner.vue";
import SettingsMobile from "./Mobile.vue";

export default {
  name: "SettingsPage",
  components: {
    SettingsUsername,
    SettingsPassword,
    SettingsPoints,
    SettingsBanner,
    SettingsMobile,
  },
  data() {
    return {
      menu: 0,
    };
  },
};
</script>

<style scoped>
@import "../../css/menu.css";
</style> 