<template>
  <div>
    <div class="bn-buttons">
      <div class="btn" @click="create">新建</div>
      <div class="btn" @click="refresh">刷新</div>
    </div>
    <div class="bn-new" v-show="newBanner">
      <input type="text" class="bn-text" v-model="newText" />
      <div class="btn bn-new-btn" @click="submit">提交</div>
    </div>
    <div class="bn-list">
      <div class="ba-item" v-for="b in list" :key="b.id">
        <div class="ba-left">
          <div class="ba-text">{{ b.text }}</div>
        </div>
        <div class="ba-right">
          <div class="ba-status">
            {{ txtStatus[b.status] }}
          </div>
          <div class="ba-delete" @click="() => remove(b.id)">删除</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PointBalance from "./PointBalance.vue";
// import PointBuy from "../components/PointBuy.vue";
// import PointSell from "./PointSell.vue";
export default {
  name: "SettingsBanner",
  data() {
    return {
      newBanner: false,
      newText: null,

      list: [],
      txtStatus: ["已提交", "已通过", "已封禁"],
    };
  },
  computed: {},
  methods: {
    create() {
      this.newBanner = true;
    },
    submit() {
      this.axios
        .post("/banner/new", { text: this.newText }, { auth: true })
        .then(({ data }) => {
          if (data.msg === "OK") {
            this.refresh();
          }
        });
    },
    remove(id) {
      this.axios
        .post("/banner/delete", { id }, { auth: true })
        .then(({ data }) => {
          if (data.msg === "OK") {
            this.refresh();
          }
        });
    },
    refresh() {
      this.axios.get("/banner/mine", { auth: true }).then(({ data }) => {
        if (data.msg === "OK") {
          this.list = data.banners;
        }
      });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
@import "../../css/btn.css";
@import "../../css/banner.css";
.bn-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bn-list {
  margin-top: 10px;
}
/* .bn-new {
  display: flex;
  flex-direction: column;
} */
.bn-text {
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 20px 0;
}
.bn-new-btn {
  display: flex;
}
</style>