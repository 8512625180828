<template>
  <div class="screen">
    <div class="content" style="padding: 20px">
      <template v-if="token">
        <SettingsUsername v-if="tab === 0" style="width: 100%" />
        <SettingsPoints v-else-if="tab === 1" style="width: 100%" />
        <SettingsBanner v-else-if="tab === 2" style="width: 100%" />
      </template>
      <template v-else>
        <RegisterAlipay v-if="tab === 0" style="width: 100%" />
      </template>
    </div>
    <div class="footer">
      <ul class="tab-list">
        <li class="tab-item" @click="back">
          <div class="tab-icon"><IconArrowLeft /></div>
          <div class="tab-text">首页</div>
        </li>
        <template v-if="token">
          <li
            class="tab-item"
            @click="tab = 0"
            :class="{ 'tab-active': tab === 0 }"
          >
            <div class="tab-text">用户名</div>
          </li>
          <li
            class="tab-item"
            @click="tab = 1"
            :class="{ 'tab-active': tab === 1 }"
          >
            <div class="tab-text">积分</div>
          </li>
          <li
            class="tab-item"
            @click="tab = 2"
            :class="{ 'tab-active': tab === 2 }"
          >
            <div class="tab-text">标语</div>
          </li>
        </template>
        <template v-else>
          <li
            class="tab-item"
            @click="tab = 0"
            :class="{ 'tab-active': tab === 0 }"
          >
            <div class="tab-text">登录</div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RegisterAlipay from "../components/logister/RegisterAlipay.vue";
import SettingsUsername from "../components/settings/SettingsUsername.vue";
import SettingsPoints from "../components/settings/SettingsPoints.vue";
import SettingsBanner from "../components/settings/SettingsBanner.vue";
import IconArrowLeft from "vue-material-design-icons/ChevronLeft.vue";

export default {
  name: "Account",
  components: {
    RegisterAlipay,
    SettingsUsername,
    IconArrowLeft,
    SettingsPoints,
    SettingsBanner,
    // RoomListMobile,
    // RoomTabs,
    // Logister,
    // Settings,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapState("account", ["token"]),
  },
  methods: {
    back() {
      location.assign("/");
    },
  },
  mounted() {
    // document.title = this.$appName;
  },
};
</script>

<style scoped>
@import "../css/init.css";
@import "../css/footer-nav.css";
</style>
